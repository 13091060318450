export default function formatMoney(
  amount?: number | null,
  currency?: string | null,
) {
  if (typeof amount !== 'number' || !isFinite(amount)) {
    return '-';
  }

  return new Intl.NumberFormat('mt-MT', {
    style: currency ? 'currency' : 'decimal',
    currency: currency || undefined,
    currencyDisplay: 'code',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);
}
