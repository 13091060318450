import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { ComponentProps, FC } from 'react';

import { Nullable } from '@/types';
import { ThemeFragment } from './__generated__/ThemeFragment';

export const theme_fragment = graphql`
  fragment ThemeFragment on SanityTheme {
    primaryColor {
      hex
    }
    secondaryColor {
      hex
    }
  }
`;

export const Theme: FC<
  ComponentProps<'div'> & { theme: Nullable<ThemeFragment> }
> = ({ children, className, theme, style = {} }) => {
  const styles = {
    ...style,
    '--color-primary': theme?.primaryColor?.hex,
    '--color-secondary': theme?.secondaryColor?.hex,
  };

  return (
    <div className={classNames('min-h-full', className)} style={styles}>
      {children}
    </div>
  );
};
