import { useParams } from '@reach/router';
// @ts-expect-error
import { insertParams } from '@reach/router/lib/utils';
import { Link as GatsbyLink, GatsbyLinkProps, graphql } from 'gatsby';
import React, { forwardRef } from 'react';

import { useTranslate } from '@/contexts';
import { useGetOperatorPath } from '@/utils/operators';
import { LinkFragment } from './__generated__/LinkFragment';

export const link_fragment = graphql`
  fragment LinkFragment on SanityLink {
    title {
      ...LocaleString
    }
    pageLink {
      path {
        current
      }
    }
    route
    link
  }
`;

type Props<T> = Omit<GatsbyLinkProps<T>, 'to' | 'ref'> & {
  link: LinkFragment;
};

export const Link = forwardRef<HTMLAnchorElement, Props<{}>>(
  ({ link, ...rest }, ref) => {
    const params = useParams();
    const { t } = useTranslate();
    const getOperatorPath = useGetOperatorPath();

    const internalLink = link.route || link.pageLink?.path?.current;
    if (internalLink) {
      const path = getOperatorPath(insertParams(internalLink, params || {}));
      return (
        // @ts-expect-error there is an issue with the types for gatsby-link.
        // But when looking at the code they handle ref forwarding correctly.
        <GatsbyLink to={path} {...rest} ref={ref}>
          {t(link.title)}
        </GatsbyLink>
      );
    }
    if (link.link) {
      return (
        <a href={link.link} {...rest} ref={ref}>
          {t(link.title)}
        </a>
      );
    }
    return null;
  },
);
