import React, { FC } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';

type DrawerContextValue = {
  isOpen: boolean;
};

const DrawerContext = createContext<DrawerContextValue | undefined>(undefined);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider: FC<{ isOpen: boolean }> = ({
  children,
  isOpen,
}) => {
  return (
    <DrawerContext.Provider value={{ isOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};
