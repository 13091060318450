import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { gql } from 'urql';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import { useTranslate } from '@/contexts';
import { LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import { LimitPeriodDetailList_periodLimit } from './__generated__/LimitPeriodDetailList_periodLimit';
import { LimitPeriodDetailStaticQuery } from './__generated__/LimitPeriodDetailStaticQuery';

const query = graphql`
  query LimitPeriodDetailStaticQuery {
    sanityLimitPeriodDetailListBit {
      title {
        ...LocaleString
      }
      periodLabel {
        ...LocaleString
      }
      valueLabel {
        ...LocaleString
      }
      availableLabel {
        ...LocaleString
      }
      configuredAtLabel {
        ...LocaleString
      }
      currentPeriodEndLabel {
        ...LocaleString
      }
      pendingLimitFromLabel {
        ...LocaleString
      }
      pendingLimitValueLabel {
        ...LocaleString
      }
    }
  }
`;

export const limitPeriodDetailList_periodLimit = gql`
  fragment LimitPeriodDetailList_periodLimit on PeriodLimit {
    value
    available
    currentPeriodEndTime
    limitPeriodType
    configuredAt
    pendingPeriodLimit {
      configuredAt
      validFrom
      value
    }
  }
`;

export const LimitPeriodDetailList: FC<{
  limitTypeTitle: string;
  limit: LimitPeriodDetailList_periodLimit;
  limitPeriodType: LimitTypeEnum;
  formatValue: (value: Nullable<number>) => string;
}> = ({ limit, limitTypeTitle, limitPeriodType, formatValue }) => {
  const { t } = useTranslate();

  const bit = useStaticQuery<LimitPeriodDetailStaticQuery>(query)
    .sanityLimitPeriodDetailListBit;

  if (!bit) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={`${t(bit.title)} - ${limitTypeTitle}`}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList
        items={[
          {
            label: t(bit.periodLabel),
            value: limitPeriodType,
          },
          {
            label: t(bit.valueLabel),
            value: formatValue(limit.value),
          },
          {
            label: t(bit.availableLabel),
            value: formatValue(limit.available),
          },
          {
            label: t(bit.configuredAtLabel),
            value: formatDate(limit.configuredAt),
          },
          {
            label: t(bit.currentPeriodEndLabel),
            value: formatDate(limit.currentPeriodEndTime),
          },
          {
            label: t(bit.pendingLimitFromLabel),
            value: formatDate(limit.pendingPeriodLimit?.validFrom),
          },
          {
            label: t(bit.pendingLimitValueLabel),
            value: formatValue(limit.pendingPeriodLimit?.value),
          },
        ]}
      />
    </Card>
  );
};
