import classNames from 'classnames';
import React, { FC } from 'react';
import { DelayWrapper, useFeedbackContainer } from 'react-feedbacker';

import { CloseIcon } from '../icons';
import styles from './Feedbacker.module.css';

export const Feedbacker: FC = () => {
  const { items, closeItem, getDelayWrapperProps } = useFeedbackContainer({
    delayCloseMs: 400,
    closeAfterMs: 5000,
  });

  return (
    <div className="fixed z-40 top-2 right-2 space-y-2">
      {items.map((item) => (
        <DelayWrapper key={item.id} {...getDelayWrapperProps({ item })}>
          <div
            className={classNames(
              `${styles.FeedbackItem} relative w-56 p-2 pr-6 text-white rounded shadow-md`,
              {
                'bg-green-500': item.kind === 'success',
                'bg-red-500': item.kind === 'error',
                'bg-purple-500': item.kind === 'info',
                'bg-yellow-500': item.kind === 'warning',
              },
            )}
            data-status={item.status}
          >
            {item.message}
            <button
              type="button"
              className="absolute right-1 top-1/2 transform -translate-y-1/2 hover:opacity-50"
              onClick={() => closeItem(item)}
            >
              <CloseIcon />
            </button>
          </div>
        </DelayWrapper>
      ))}
    </div>
  );
};
