import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { BaseInputFieldProps } from './BaseInputField';
import { BaseWrapper } from './BaseWrapper';
import { ErrorMessage } from './ErrorMessage';

type Props = BaseInputFieldProps & {
  value?: string;
  labelPlacement?: 'above' | 'right';
};

export const CheckboxField = ({
  title,
  name,
  required,
  className,
  labelPlacement = 'right',
  ...rest
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const field = (
    <input
      id={name}
      placeholder={title}
      autoComplete="off"
      {...rest}
      type="checkbox"
      {...register(name, { required, ...rest })}
    />
  );

  if (labelPlacement === 'above') {
    return (
      <BaseWrapper name={name} title={title}>
        {field}
      </BaseWrapper>
    );
  }

  return (
    <div className={classNames('w-full', className)}>
      <label className="flex items-center space-x-2">
        {field}
        <span className="focus:outline-none focus:ring w-full sm:text-sm dark:text-gray-200">
          {title}
          {required && '*'}
        </span>
      </label>
      {errors[name] && <ErrorMessage message={errors[name]} variant="inline" />}
    </div>
  );
};
