import { navigate } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Client, useClient } from 'urql';

import {
  useGetGamePageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import { useElasticIndexPrefix } from '@/contexts';
import useSearchWorker from '@/hooks/useSearchWorker';
import { Nullable } from '@/types';
import { makeBrandThumbnail } from '@/utils/makeBrandThumbnail';
import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import {
  PlayerSearchAsYouType,
  PlayerSearchAsYouTypeVariables,
} from './__generated__/PlayerSearchAsYouType';

const PLAYERS_AS_YOU_TYPE_QUERY = gql`
  query PlayerSearchAsYouType($query: String!, $indexPrefix: String) {
    viewer {
      id
      playersAsYouTypeV2(
        firstName: $query
        lastName: $query
        playerId: $query
        indexPrefix: $indexPrefix
      ) {
        edges {
          node {
            playerId
            firstName
            lastName
            brand {
              id
              code
            }
          }
        }
      }
    }
  }
`;

type TypeMe = any;

const Option: FC<TypeMe> = (props) => {
  const fluid = props.value.brand
    ? makeBrandThumbnail(props.value.brand)
    : props.value.thumbnail
    ? makeFluidThumbnail(props.value.thumbnail, props.value.thumbnailUpdatedAt)
    : null;

  return (
    <components.Option {...props}>
      <div className="flex flex-row w-full items-center">
        {fluid ? (
          <picture>
            <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
            <img
              src={fluid.src}
              alt=""
              loading="lazy"
              className="w-6 h-6 rounded-sm mr-2"
            />
          </picture>
        ) : (
          <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
        )}
        <div className="flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap">
          {props.children}
        </div>
        <div className="ml-3 text-xs text-gray-500 uppercase">
          {props.value.playerId ? 'Player' : 'Game'}
        </div>
      </div>
    </components.Option>
  );
};

const loadOptions = (
  searchWorker: any,
  client: Client,
  indexPrefix: Nullable<string>,
) => (queryInput: string, callback: Function) => {
  const trimmedQuery = queryInput.trim();

  if (trimmedQuery.length > 0) {
    const players = client
      .query<PlayerSearchAsYouType, PlayerSearchAsYouTypeVariables>(
        PLAYERS_AS_YOU_TYPE_QUERY,
        {
          query: trimmedQuery,
          indexPrefix,
        },
      )
      .toPromise();

    const games = searchWorker.search({ limit: 20, query: trimmedQuery });

    Promise.all([players, games]).then(([playerRes, gamesRes]) => {
      const p =
        playerRes?.data?.viewer.playersAsYouTypeV2?.edges?.map((edge) => ({
          value: edge?.node,
          label: `${edge?.node.firstName} ${edge?.node.lastName}`,
        })) || [];

      const g =
        // @ts-expect-error should not be needed once gameRes is typed
        gamesRes.hits.map(({ item }) => ({
          value: item,
          label: item.name,
        })) || [];

      callback([...p, ...g]);
    });
  } else {
    callback([]);
  }
};

export const GlobalSearch = () => {
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();
  const { indexPrefix } = useElasticIndexPrefix();
  const getGamePageLink = useGetGamePageLink();

  const onChange = ({ value }: any) => {
    if (value.playerId) {
      const link = getPlayerOverviewPageLink(value.playerId);
      if (link) {
        return navigate(link);
      }
    }
    if (value.slug) {
      const link = getGamePageLink(value.slug);
      if (link) {
        return navigate(link);
      }
    }
    return;
  };

  const { searchWorker } = useSearchWorker();
  const client = useClient();

  return (
    <AsyncSelect
      className="w-full md:w-96"
      cacheOptions
      value={null}
      components={{ Option }}
      placeholder="Search..."
      loadOptions={
        searchWorker && loadOptions(searchWorker, client, indexPrefix)
      }
      defaultOptions
      onChange={onChange}
    />
  );
};
