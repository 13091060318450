import React, { FC } from 'react';

import { InlineSpinner } from './InlineSpinner';

export const Bar: FC<{ value: number; loading: boolean }> = ({
  value,
  loading,
  children,
}) => (
  <div className="shadow w-full h-6 bg-gray-300 dark:bg-gray-700 leading-none text-sm text-black dark:text-gray-200 relative px-1 py-1">
    {loading ? (
      <InlineSpinner />
    ) : (
      <>
        {!isNaN(value) && value > 0 && (
          <div
            className="bg-red-500 h-6 text-white overflow-hidden whitespace-nowrap absolute py-1 px-1 -mt-1 -ml-1"
            style={{ width: `${Math.min(value, 1) * 100}%` }}
          >
            {children}
          </div>
        )}
        {children}
      </>
    )}
  </div>
);
