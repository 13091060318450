import flag from 'country-code-emoji';
import { getName } from 'country-list';

const CC_REGEX = /^[a-z]{2}$/i;

export function validateCountryCode(code?: string | null) {
  return !!code && CC_REGEX.test(code);
}

export default function formatCountryCode(code?: string | null): string {
  if (!code) {
    return '-';
  }

  return `${CC_REGEX.test(code) ? flag(code) : ''} ${getName(code) || code}`;
}
