import { OktaAuth } from '@okta/okta-auth-js';
import { makeOperation } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { feedback } from 'react-feedbacker';
import {
  CombinedError,
  Operation,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
} from 'urql';

import schema from '../../backoffice-schema.json';
import { urqlMutationUpdates } from './urqlMutationUpdates';

let lastError = { text: '', time: 0 };

const hasInvalidOktaToken = (error: CombinedError) => {
  return error.graphQLErrors.some(
    (gqlError) =>
      // @ts-expect-error errorCode does exist on originalError
      gqlError.originalError?.errorCode === 'INVALID_OKTA_TOKEN',
  );
};

export const makeClient = (url: string, oktaAuth: OktaAuth) => {
  const cache = cacheExchange({
    // @ts-expect-error: we know that this is a valid schema
    schema,
    keys: {
      AccountStatus: () => null,
      AdjustmentTypeData: () => null,
      AffiliateInfo: () => null,
      Agent: ({ agentId }: any) => agentId,
      AgentInitiator: () => null,
      AllLinkedAccounts: () => null,
      AuthenticationMethod: () => null,
      Brand: ({ code }: any) => code,
      CashbackTransactionItem: () => null,
      CustomerRiskAssessment: ({ playerGlobalId }: any) => playerGlobalId,
      CustomerRiskAssessmentHistoryItem: () => null,
      DepositInfo: () => null,
      EmailInfo: () => null,
      FinalRiskLevelUpdated: () => null,
      FreeSpinReward: () => null,
      FundingDocumentsRequestedItem: () => null,
      GameDescriptor: (data: any) => data?.json?.gameId,
      GameRoundItem: () => null,
      Identity: () => null,
      KycCheckStatus: () => null,
      Limit: () => null,
      LimitSearchItem: () => null,
      LinkedAccounts: () => null,
      LoginInfo: () => null,
      MoneyDropReward: () => null,
      Payment: () => null,
      PendingLimit: () => null,
      PeriodLimit: () => null,
      PlayerAddressInfo: () => null,
      PlayerComplianceDetails: () => null,
      PlayerComplianceGrouped: () => null,
      PlayerExportDetails: () => null,
      PlayerInitiator: () => null,
      PlayerKYCValidationItem: () => null,
      PlayerKYCVerifiedItem: () => null,
      PlayerLoginInfo: () => null,
      PlayerLoginInfoData: () => null,
      PlayerRewardFlatItem: () => null,
      PlayerSearchItem: ({ playerId }: any) => playerId,
      Region: () => null,
      RegistrationInfo: () => null,
      RewardItem: ({ rewardId }: any) => rewardId,
      Risk: ({ riskId }: any) => riskId,
      RiskLevelUpdated: () => null,
      RiskOverrideAdded: () => null,
      RiskOverrideRemoved: () => null,
      SelfExclusionData: () => null,
      SessionGroupItem: () => null,
      SessionSearchGroupedItem: () => null,
      SourceOfWealth: () => null,
      StatsEntryType: () => null,
      StatsType: () => null,
      TransactionSearchItem: ({ uuid }: any) => uuid,
      VerificationWithProvider: () => null,
    },
    updates: {
      Mutation: urqlMutationUpdates,
    },
  });

  const ourAuthExchange = authExchange({
    addAuthToOperation: ({ authState, operation }) => {
      if (!authState || !authState.accessToken) {
        return operation;
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${authState.accessToken}`,
          },
        },
      });
    },
    willAuthError: ({ authState }) => !authState,
    didAuthError: ({ error }) => hasInvalidOktaToken(error),
    getAuth: async () => {
      const accessToken = (await oktaAuth.tokenManager.getTokens()).accessToken
        ?.accessToken;
      return {
        accessToken,
      };
    },
  });

  return createClient({
    url,
    fetchOptions: {
      headers: {
        accept: 'application/json',
      },
    },
    exchanges: [
      errorExchange({
        onError: (error: CombinedError, operation: Operation) => {
          let message = error.message;
          if (message === '[Network] Failed to fetch') {
            message += `. Are you connected to the VPN?`;
          }

          if (hasInvalidOktaToken(error)) {
            return;
          }

          // this will make sure to not show same message more than once for 10 seconds
          const now = Date.now();
          if (lastError.text !== message || now - lastError.time > 10000) {
            lastError = { text: message, time: now };
            feedback.error(message);
          }
          console.log({ error, operation });
        },
      }),
      devtoolsExchange,
      dedupExchange,
      cache,
      ourAuthExchange,
      fetchExchange,
    ],
  });
};
