import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  isBefore,
  subMonths,
} from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { TranslateFn, useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  UseTimeAgoBitStaticQuery,
  UseTimeAgoBitStaticQuery_sanityTimeAgoBit,
} from './__generated__/UseTimeAgoBitStaticQuery';

const query = graphql`
  query UseTimeAgoBitStaticQuery {
    sanityTimeAgoBit {
      about {
        ...LocaleString
      }
      yearUnit {
        ...LocaleString
      }
      yearsUnit {
        ...LocaleString
      }
      monthUnit {
        ...LocaleString
      }
      monthsUnit {
        ...LocaleString
      }
      dayUnit {
        ...LocaleString
      }
      daysUnit {
        ...LocaleString
      }
      hourUnit {
        ...LocaleString
      }
      hoursUnit {
        ...LocaleString
      }
      minuteUnit {
        ...LocaleString
      }
      minutesUnit {
        ...LocaleString
      }
      justNow {
        ...LocaleString
      }
      ago {
        ...LocaleString
      }
    }
  }
`;

type GetTimeAgo = (
  date: Nullable<string | number | Date>,
) => string | undefined;

const shouldRoundUpToNextHour = (minutesDiff: number) => minutesDiff % 60 >= 30;
const shouldRoundUpToNextDay = (hoursDiff: number) => hoursDiff % 24 >= 18;

const shouldRoundUpToNextMonth = (
  monthsDiff: number,
  now: Date,
  targetDate: Date,
) => {
  const sameMonthDate = subMonths(now, monthsDiff);
  return differenceInDays(sameMonthDate, targetDate) > 14;
};

type DiffDetails = {
  minutesDiff: number;
  hoursDiff: number;
  daysDiff: number;
  monthsDiff: number;
  yearsDiff: number;
};

type GetTimeAgoFn = ({
  timeAgoBit,
  t,
  now,
  date,
}: {
  t: TranslateFn;
  timeAgoBit: UseTimeAgoBitStaticQuery_sanityTimeAgoBit;
  now: Date;
  date: Date;
}) => (details: DiffDetails) => string | undefined;

const createGetMinutesDiffMessage: GetTimeAgoFn =
  ({ t, timeAgoBit }) =>
  ({ minutesDiff }) => {
    switch (true) {
      case minutesDiff < 1:
        return t(timeAgoBit.justNow);

      case minutesDiff < 2:
        return `1 ${t(timeAgoBit.minuteUnit)} ${t(timeAgoBit.ago)}`;

      case minutesDiff < 50: {
        return `${minutesDiff} ${t(timeAgoBit.minutesUnit)} ${t(
          timeAgoBit.ago,
        )}`;
      }

      case minutesDiff < 90:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.hourUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      default:
        return undefined;
    }
  };

const createGetHoursDiffMessage: GetTimeAgoFn =
  ({ t, timeAgoBit }) =>
  ({ hoursDiff, minutesDiff }) => {
    switch (true) {
      case hoursDiff < 24:
        return `${t(timeAgoBit.about)} ${
          shouldRoundUpToNextHour(minutesDiff) ? hoursDiff + 1 : hoursDiff
        } ${t(timeAgoBit.hoursUnit)} ${t(timeAgoBit.ago)}`;

      case hoursDiff < 42:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.dayUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      default:
        return undefined;
    }
  };

const createGetDaysDiffMessage: GetTimeAgoFn =
  ({ t, timeAgoBit, now, date }) =>
  ({ hoursDiff, daysDiff, monthsDiff }) => {
    switch (true) {
      case daysDiff < 30:
        return `${t(timeAgoBit.about)} ${
          shouldRoundUpToNextDay(hoursDiff) ? daysDiff + 1 : daysDiff
        } ${t(timeAgoBit.daysUnit)} ${t(timeAgoBit.ago)}`;

      case daysDiff < 45 ||
        (monthsDiff === 1 && !shouldRoundUpToNextMonth(monthsDiff, now, date)):
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.monthUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      default:
        return undefined;
    }
  };

const createGetYearsDiffMessage: GetTimeAgoFn =
  ({ t, timeAgoBit, now, date }) =>
  ({ yearsDiff, monthsDiff }) => {
    switch (true) {
      case yearsDiff < 1: {
        const roundUpMonth = shouldRoundUpToNextMonth(monthsDiff, now, date);
        return `${t(timeAgoBit.about)} ${
          roundUpMonth ? monthsDiff + 1 : monthsDiff
        } ${t(timeAgoBit.monthsUnit)} ${t(timeAgoBit.ago)}`;
      }

      case yearsDiff < 2:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.yearUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      default:
        return undefined;
    }
  };

const warnInNonTest = (message: string) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  console.warn(message);
};

export const createGetTimeAgo = ({
  t,
  timeAgoBit,
}: {
  t: TranslateFn;
  timeAgoBit: UseTimeAgoBitStaticQuery_sanityTimeAgoBit;
}): GetTimeAgo => {
  const getTimeAgo: GetTimeAgo = (dateValue) => {
    if (!dateValue) {
      return '-';
    }

    const date = new Date(dateValue);
    const now = new Date();

    if (isBefore(now, date)) {
      warnInNonTest('The target date is in the future');
      return undefined;
    }

    const minutesDiff = differenceInMinutes(now, date);
    const hoursDiff = differenceInHours(now, date);
    const daysDiff = differenceInDays(now, date);
    const monthsDiff = differenceInMonths(now, date);
    const yearsDiff = differenceInYears(now, date);

    const createGetTimeAgoArg: Parameters<GetTimeAgoFn>[0] = {
      date,
      now,
      t,
      timeAgoBit,
    };

    const getTimeAgoFns = [
      createGetMinutesDiffMessage(createGetTimeAgoArg),
      createGetHoursDiffMessage(createGetTimeAgoArg),
      createGetDaysDiffMessage(createGetTimeAgoArg),
      createGetYearsDiffMessage(createGetTimeAgoArg),
    ];

    for (let index = 0; index < getTimeAgoFns.length; index++) {
      const getTimeAgo = getTimeAgoFns[index];

      const timeAgo = getTimeAgo({
        daysDiff,
        hoursDiff,
        minutesDiff,
        monthsDiff,
        yearsDiff,
      });

      if (timeAgo) {
        return timeAgo;
      }
    }

    return `${yearsDiff}+ ${t(timeAgoBit.yearsUnit)} ${t(timeAgoBit.ago)}`;
  };

  return getTimeAgo;
};

export const useTimeAgo = (): GetTimeAgo => {
  const staticData = useStaticQuery<UseTimeAgoBitStaticQuery>(query);
  const { t } = useTranslate();

  const timeAgoBit = staticData.sanityTimeAgoBit;
  assert(timeAgoBit, 'missing feedback messages');

  const getTimeAgo = useMemo(
    () =>
      createGetTimeAgo({
        t,
        timeAgoBit,
      }),
    [t, timeAgoBit],
  );

  return getTimeAgo;
};
