import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/input';

import { BaseInputFieldProps } from './BaseInputField';
import { BaseWrapper } from './BaseWrapper';

export const PhoneNumberField = ({
  title,
  name,
  ...props
}: BaseInputFieldProps) => {
  const { control } = useFormContext();
  return (
    <BaseWrapper title={title} name={name} {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: props.required,
        }}
        render={({ field }) => (
          <PhoneInput
            placeholder={title}
            className="mt-1 focus:ring block w-full sm:text-sm border-gray-300 rounded-md"
            {...field}
          />
        )}
      />
    </BaseWrapper>
  );
};
