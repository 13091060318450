import { ReactElement, ReactNode } from 'react';

type Props = {
  condition: boolean;
  wrap: (children: React.ReactElement) => JSX.Element | null;
  children: ReactNode;
};

export const ConditionalWrap = ({
  condition,
  wrap,
  children: childrenReactNode,
}: Props) => {
  const children = (childrenReactNode as ReactElement) ?? null;

  return condition ? wrap(children) : children;
};
