export function makeFluidThumbnail(
  thumbnailPath: string,
  thumbnailUpdatedAt: number,
) {
  return {
    aspectRatio: 1,
    src: `${process.env
      .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-200,q-50${thumbnailPath}?u=${thumbnailUpdatedAt}`,
    sizes: '(max-width: 575px) 50vw, (max-width: 991px) 25vw, 20vw',
    srcSet: `${process.env
      .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-180,h-180,q-50${thumbnailPath}?u=${thumbnailUpdatedAt} 180w,
${process.env
  .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-340,h-340,q-50${thumbnailPath}?u=${thumbnailUpdatedAt} 340w,
${process.env
  .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-380,h-380,q-50${thumbnailPath}?u=${thumbnailUpdatedAt} 380w,
${process.env
  .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-480,h-480,q-50${thumbnailPath}?u=${thumbnailUpdatedAt} 480w,
${process.env
  .GATSBY_IMAGEKIT_ENDPOINT!}/tr:w-540,h-540,q-50${thumbnailPath}?u=${thumbnailUpdatedAt} 540w`,
  };
}
