import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { useSessionStorage } from 'react-use';

import { BlockRenderer } from '@/blocks/BlockRenderer';
import { Button, CardsWrapper, Link } from '@/components';
import { DrawerProvider } from '@/contexts';
import { cleanArray } from '@/utils';
import { PageTemplateFragment } from './__generated__/PageTemplateFragment';
import { HamburgerIcon } from './icons';

export const pageTemplateFragment = graphql`
  fragment PageTemplateFragment on SanityPageTemplate {
    navMenu {
      items {
        _key
        ...LinkFragment
      }
    }
    content {
      ...BlockRendererFragment
    }
    drawer {
      ...BlockRendererFragment
    }
  }
`;

export const PageTemplate: React.FC<{
  template: PageTemplateFragment;
  pageContent: ReactNode;
}> = ({ template, pageContent }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useSessionStorage(
    'drawerIsOpen',
    false,
  );
  return (
    <>
      {template.navMenu && (
        <div className="md:w-48 md:block bg-white dark:bg-gray-900 md:border-r-2 dark:border-gray-700 w-screen">
          <ul className="pl-3 flex space-x-1 md:space-x-0 md:space-y-1 md:p-3 py-3 md:block whitespace-nowrap w-full overflow-x-auto">
            {cleanArray(template.navMenu.items).map((link) => (
              <li key={link._key}>
                <Link
                  className="px-2 py-1 md:px-3 md:py-2 rounded w-full block whitespace-nowrap overflow-hidden overflow-ellipsis hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                  activeClassName="bg-blue-100 hover:bg-blue-100 dark:bg-gray-700 dark:hover:bg-gray-800"
                  link={link}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="flex-1 grid" style={{ gridTemplateColumns: '1fr auto' }}>
        {template.content && (
          <div>
            <CardsWrapper>
              <BlockRenderer
                blockContainer={template.content}
                outletContent={pageContent}
              />
            </CardsWrapper>
          </div>
        )}
        {template.drawer && (
          <DrawerProvider isOpen={drawerIsOpen}>
            <div
              className={classNames('grid relative bg-white dark:bg-gray-800', {
                'w-96': drawerIsOpen,
                'w-0': !drawerIsOpen,
              })}
            >
              <div className="absolute top-0 right-full">
                <Button
                  onClick={() => setDrawerIsOpen(!drawerIsOpen)}
                  className="px-3 py-3 bg-white hover:bg-gray-200 dark:bg-gray-900 dark:hover:bg-gray-600 shadow rounded rounded-r-none dark:text-gray-200"
                >
                  <HamburgerIcon />
                </Button>
              </div>
              {drawerIsOpen && (
                <div>
                  <BlockRenderer blockContainer={template.drawer} />
                </div>
              )}
            </div>
          </DrawerProvider>
        )}
      </div>
    </>
  );
};
