import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import { useTimeAgo } from '@/bits/time-ago';
import { Copy } from '@/components';
import formatDate from '@/utils/formatter/formatDate';

type DatePresentation = 'timeAgo' | 'formattedDate';

export const Date: FC<{
  date?: Parameters<typeof formatDate>[0];
  copyable?: boolean;
  presentation?: DatePresentation;
}> = ({ date, copyable, presentation = 'formattedDate' }) => {
  const getTimeAgo = useTimeAgo();
  const staticFormattedValue = formatDate(date);

  const children =
    presentation === 'timeAgo' ? (
      <Tippy content={staticFormattedValue}>
        <span>{getTimeAgo(date)}</span>
      </Tippy>
    ) : (
      <span className="tabular-nums">{staticFormattedValue}</span>
    );

  if (copyable) {
    return <Copy value={staticFormattedValue}>{children}</Copy>;
  }

  return children;
};
