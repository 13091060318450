import { useEffect, useRef } from 'react';

export function useFunctionRef<T>(fn: T) {
  const fnRef = useRef<T>(fn);

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  return fnRef;
}
