import classNames from 'classnames';
import React, { ComponentProps, FC } from 'react';

import { CloseIcon } from '@/components/icons';

export const Pill: FC<{ onClick?: ComponentProps<'button'>['onClick'] }> = ({
  children,
  onClick,
}) => (
  <div className="inline-flex items-center justify-center text-xs font-bold leading-none bg-blue-200 text-blue-600 dark:bg-blue-800 dark:text-blue-200 rounded overflow-hidden">
    <span className={classNames('pl-1 py-1', !onClick && 'p-1')}>
      {children}
    </span>
    {onClick && (
      <button
        onClick={onClick}
        className="transition-colors hover:bg-blue-400 p-1"
      >
        <CloseIcon />
      </button>
    )}
  </div>
);
