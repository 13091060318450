// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blocks-analytics-block-visualizations-table-visualization-example-tsx": () => import("./../../../src/blocks/analytics-block/visualizations/table-visualization.example.tsx" /* webpackChunkName: "component---src-blocks-analytics-block-visualizations-table-visualization-example-tsx" */),
  "component---src-components-breadcrumbs-breadcrumbs-example-tsx": () => import("./../../../src/components/Breadcrumbs/breadcrumbs.example.tsx" /* webpackChunkName: "component---src-components-breadcrumbs-breadcrumbs-example-tsx" */),
  "component---src-components-card-card-example-tsx": () => import("./../../../src/components/Card/card.example.tsx" /* webpackChunkName: "component---src-components-card-card-example-tsx" */),
  "component---src-components-detail-list-detail-list-example-tsx": () => import("./../../../src/components/DetailList/detail-list.example.tsx" /* webpackChunkName: "component---src-components-detail-list-detail-list-example-tsx" */),
  "component---src-components-feedbacker-feedbacker-example-tsx": () => import("./../../../src/components/Feedbacker/feedbacker.example.tsx" /* webpackChunkName: "component---src-components-feedbacker-feedbacker-example-tsx" */),
  "component---src-components-form-switch-field-switch-field-example-tsx": () => import("./../../../src/components/form/SwitchField/switch-field.example.tsx" /* webpackChunkName: "component---src-components-form-switch-field-switch-field-example-tsx" */),
  "component---src-components-game-search-game-search-example-tsx": () => import("./../../../src/components/GameSearch/GameSearch.example.tsx" /* webpackChunkName: "component---src-components-game-search-game-search-example-tsx" */),
  "component---src-components-icons-icons-example-tsx": () => import("./../../../src/components/icons/icons.example.tsx" /* webpackChunkName: "component---src-components-icons-icons-example-tsx" */),
  "component---src-components-options-menu-options-menu-example-tsx": () => import("./../../../src/components/OptionsMenu/options-menu.example.tsx" /* webpackChunkName: "component---src-components-options-menu-options-menu-example-tsx" */),
  "component---src-components-players-search-players-search-example-tsx": () => import("./../../../src/components/PlayersSearch/PlayersSearch.example.tsx" /* webpackChunkName: "component---src-components-players-search-players-search-example-tsx" */),
  "component---src-components-timepicker-time-picker-2-example-tsx": () => import("./../../../src/components/Timepicker/TimePicker2.example.tsx" /* webpackChunkName: "component---src-components-timepicker-time-picker-2-example-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-graphql-tsx": () => import("./../../../src/pages/graphql.tsx" /* webpackChunkName: "component---src-pages-graphql-tsx" */),
  "component---src-pages-implicit-callback-tsx": () => import("./../../../src/pages/implicit/callback.tsx" /* webpackChunkName: "component---src-pages-implicit-callback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

