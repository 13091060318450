import { toDate } from 'date-fns';

export const eachMinuteOfInterval = (
  dirtyInterval: Interval,
  options?: { step?: number },
): Date[] => {
  const interval = dirtyInterval || {};
  const startDate = toDate(interval.start);
  const endDate = toDate(interval.end);

  const endTime = endDate.getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    throw new RangeError('Invalid interval');
  }

  const dates = [];

  const currentDate = startDate;
  currentDate.setSeconds(0, 0);

  const step = options && 'step' in options ? Number(options.step) : 1;
  if (step < 1 || isNaN(step))
    throw new RangeError('`options.step` must be a number greater than 1');
  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate));
    currentDate.setMinutes(currentDate.getMinutes() + step);
    currentDate.setSeconds(0, 0);
  }

  return dates;
};
