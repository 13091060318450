import { GameProvider } from '@/globalTypes';

const availableGameProviders = ['Relax', 'Pragmatic'];

const gameProviders = Object.keys(GameProvider).map((provider) => {
  return { label: provider, value: provider };
});

export const rewardGameProviders = gameProviders.filter((provider) =>
  availableGameProviders.includes(provider.value),
);
