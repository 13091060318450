import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import {
  PlayerDocuments,
  PlayerDocuments_player_documents_edges_node,
} from '@/blocks/player-documents-block/__generated__/PlayerDocuments';
import { PLAYER_DOCUMENTS_QUERY } from '@/blocks/player-documents-block/usePlayerDocuments';
import { FundingDocumentUploadedMutation } from '@/forms/player-document-upload-form/__generated__/FundingDocumentUploadedMutation';
import { UploadDocumentMutation } from '@/forms/player-document-upload-form/__generated__/UploadDocumentMutation';

const getPlayerFromCache = (cache: Cache, playerId: string) => {
  return cache
    .inspectFields('Query')
    .find(
      (f) => f.fieldName === 'player' && f.arguments?.playerId === playerId,
    );
};

const inspectPlayerFields = (cache: Cache, playerId: string) => {
  return cache.inspectFields({
    __typename: 'Player',
    id: playerId,
  });
};

const prependToPlayerDocuments = (
  cache: Cache,
  playerId: string,
  node: PlayerDocuments_player_documents_edges_node,
) => {
  const player = getPlayerFromCache(cache, playerId);

  if (player) {
    inspectPlayerFields(cache, playerId)
      .filter((f) => f.fieldName === 'documents')
      .forEach((f) => {
        cache.updateQuery<PlayerDocuments, any>(
          {
            query: PLAYER_DOCUMENTS_QUERY,
            variables: { ...player.arguments, ...f.arguments },
          },
          (data) => {
            if (data) {
              data.player.documents.edges = [
                {
                  __typename: 'DocumentEdge',
                  node,
                },
                ...(data.player.documents.edges ?? []),
              ];
            }
            return data;
          },
        );
      });
  }
};

const fundingDocumentUploaded: UpdateResolver<
  FundingDocumentUploadedMutation,
  any
> = (parent, args, cache) =>
  prependToPlayerDocuments(
    cache,
    args.playerId,
    parent.fundingDocumentUploaded,
  );

const uploadDocumentV2: UpdateResolver<UploadDocumentMutation, any> = (
  parent,
  args,
  cache,
) => prependToPlayerDocuments(cache, args.playerId, parent.uploadDocumentV2);

export const urqlMutationUpdates: Record<string, UpdateResolver<any, any>> = {
  fundingDocumentUploaded,
  uploadDocumentV2,
};
