import React from 'react';
import { ComponentProps } from 'react';

import { DateField } from './DateField';

type Props = Omit<ComponentProps<typeof DateField>, 'showTimeInput'>;

export const DateTimeField = (props: Props) => {
  return <DateField {...props} showTimeInput />;
};
