import { TimeValue } from '@/contexts';
import { GranularityEnum } from '@/globalTypes';
import {
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachSecondOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
} from '@/utils';

const getStartOfInterval = (interval: Interval) => {
  if (typeof interval.start === 'number') {
    return [new Date(interval.start)];
  }
  return [interval.start];
};

type IntervalToDateRange = (interval: Interval) => Date[];

const intervalMap: Record<GranularityEnum, IntervalToDateRange> = {
  Second: eachSecondOfInterval,
  Minute: eachMinuteOfInterval,
  Hour: eachHourOfInterval,
  Day: eachDayOfInterval,
  Week: eachWeekOfInterval,
  Month: eachMonthOfInterval,
  Quarter: eachQuarterOfInterval,
  Year: eachYearOfInterval,
  All: getStartOfInterval,
};

export const eachDatePointOfInterval = (time: TimeValue) => {
  const interval = { start: time.from, end: time.to };

  const value = intervalMap[time.granularity]?.(interval);

  if (value) {
    return value;
  }

  throw new Error(`no implementation for granularity: ${time.granularity}`);
};
