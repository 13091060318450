import _ from 'lodash';
import React, { FC } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  DateField,
  Form,
  SelectField,
  SubmitButton,
} from '@/components';
import { TimeGranularity, useTime } from '@/contexts';
import { predefinedTimeFrames } from '@/contexts/TimeContext/predefinedTimeFrames';
import formatDate from '@/utils/formatter/formatDate';
import { timeZones } from '@/utils/timeZones';

export const TimePicker: FC<{ buttonClassName?: string }> = ({
  buttonClassName,
}) => {
  const {
    setCustomTime,
    setPredefinedTime,
    time: { from, to, timeZone, granularity },
    timeFrame,
    timeGranularity,
  } = useTime();

  return (
    <ControlledModal
      content={({ close }) => (
        <Card
          size="md"
          title="Time"
          options={
            <CardOptions>
              <CardCloseButton />
            </CardOptions>
          }
        >
          <CardBody className="p-3">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
              {Object.values(predefinedTimeFrames).map((tf) => (
                <Button
                  variant={timeFrame.name === tf.name ? 'primary' : 'secondary'}
                  key={tf.name}
                  onClick={() => {
                    setPredefinedTime({ name: tf.name });
                    close();
                  }}
                >
                  {_.startCase(tf.name)}
                </Button>
              ))}
            </div>
            <Form
              defaultValues={{ from, to, timeZone, timeGranularity }}
              onSubmit={(values) => {
                setCustomTime(values);
                close();
              }}
              className="grid gap-3 mt-3"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <DateField title="From" name="from" id="TimePicker__from" />
                <DateField title="To" name="to" id="TimePicker__to" />
              </div>
              <SelectField
                title="Granularity"
                name="timeGranularity"
                id="TimePicker__timeGranularity"
                required
                isClearable={false}
                options={Object.values(TimeGranularity).map((a) => ({
                  value: a,
                  label: a,
                }))}
              />

              <SelectField
                title="Timezone"
                name="timeZone"
                id="TimePicker__timeZone"
                required
                isClearable={false}
                options={timeZones.map((zone) => ({
                  value: zone.timeZone,
                  label: `(${zone.timeOffset}) ${zone.country}${
                    zone.cities ? `: ${zone.cities}` : ''
                  }`,
                }))}
              />

              <SubmitButton value="Save" />
            </Form>
          </CardBody>
        </Card>
      )}
    >
      <Button className={buttonClassName}>
        {timeFrame.name === 'custom'
          ? `${formatDate(from, true)} - ${formatDate(to, true)}`
          : _.startCase(timeFrame.name)}
        {` (${granularity})`}
      </Button>
    </ControlledModal>
  );
};
