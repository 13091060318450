import { Context, createContext, useContext } from 'react';

import { Nullable } from '@/types';

export class ContextBuilder<ContextType> {
  Context: Context<Nullable<ContextType>>;

  constructor(contextName: string) {
    const context = createContext<Nullable<ContextType>>(null);
    context.displayName = contextName;

    this.Context = context;
  }

  use = () => {
    // This is not a class component, only a class acting as a wrapper - this is legit
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const value = useContext(this.Context);

    if (!!value) {
      // At this point, we know that value is neither null or undefined
      return value as NonNullable<ContextType>;
    }

    throw new Error(
      `Tried to use context for ${this.Context.displayName} outside of its provider.`,
    );
  };
}
