import { Nullable } from '@/types';

export type ReplaceMacrosParams = Record<string, Nullable<string | number>>;

export const replaceMacros = <T extends string | undefined | null>(
  str: T,
  params: ReplaceMacrosParams,
  macroRegexp = /(\{\{(\w+)\}\})/g,
) => {
  if (!str) {
    return str;
  }
  const errors: { key: unknown; replacement: string }[] = [];
  let gotError = false;
  const result = str.replace(macroRegexp, (...args) => {
    const replacement = params[args[2]];
    if (replacement !== undefined && replacement !== null) {
      if (typeof replacement === 'string' || typeof replacement === 'number') {
        return `${replacement}`;
      }
      gotError = true;
      errors.push({ key: args[2], replacement });
      return '[ERROR]';
    }
    return args[0];
  });
  if (gotError) {
    console.error(`Errors while replacing macros for: "${str}"`, errors);
  }
  return result;
};
