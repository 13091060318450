import React, { ComponentProps, FC } from 'react';
import { FieldError } from 'react-hook-form';

import { Alert } from '@/components';
import { Nullable } from '@/types';

type ErrorOrMessage = string | FieldError;

const isFieldError = (error: Nullable<ErrorOrMessage>): error is FieldError => {
  return (
    !!error &&
    typeof error === 'object' &&
    'type' in error &&
    'message' in error
  );
};

export const ErrorMessage: FC<{
  message: Nullable<ErrorOrMessage>;
  variant?: ComponentProps<typeof Alert>['variant'];
}> = ({ message: errorOrMessage, variant = 'box' }) => {
  const message = isFieldError(errorOrMessage)
    ? errorOrMessage.message || errorOrMessage.type
    : errorOrMessage;

  if (!message) {
    return null;
  }

  console.log('[ERROR]', message);

  return (
    <Alert variant={variant}>{message.replace(/^\[\w+\]/i, '').trim()}</Alert>
  );
};
