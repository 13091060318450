export const timeZones = [
  {
    timeOffset: 'UTC-11:00',
    timeZone: 'Pacific/Midway',
    country: 'United States Minor Outlying Islands',
    cities: '',
  },
  {
    timeOffset: 'UTC-11:00',
    timeZone: 'Pacific/Niue',
    country: 'Niue',
    cities: 'Alofi',
  },
  {
    timeOffset: 'UTC-11:00',
    timeZone: 'Pacific/Pago_Pago',
    country: 'American Samoa',
    cities: 'Pago Pago, Tāfuna, Ta`ū, Taulaga',
  },
  {
    timeOffset: 'UTC-10:00',
    timeZone: 'America/Adak',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-10:00',
    timeZone: 'Pacific/Honolulu',
    country: 'United States',
    cities: 'Honolulu, East Honolulu, Pearl City, Hilo, Kailua',
  },
  {
    timeOffset: 'UTC-10:00',
    timeZone: 'Pacific/Rarotonga',
    country: 'Cook Islands',
    cities: 'Avarua',
  },
  {
    timeOffset: 'UTC-10:00',
    timeZone: 'Pacific/Tahiti',
    country: 'French Polynesia',
    cities: 'Faaa, Papeete, Punaauia, Pirae, Mahina',
  },
  {
    timeOffset: 'UTC-9:30',
    timeZone: 'Pacific/Marquesas',
    country: 'French Polynesia',
    cities: 'Taiohae',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Anchorage',
    country: 'United States',
    cities: 'Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Juneau',
    country: 'United States',
    cities: 'Juneau',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Metlakatla',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Nome',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Sitka',
    country: 'United States',
    cities: 'Sitka, Ketchikan',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'America/Yakutat',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-9:00',
    timeZone: 'Pacific/Gambier',
    country: 'French Polynesia',
    cities: '',
  },
  {
    timeOffset: 'UTC-8:00',
    timeZone: 'America/Los_Angeles',
    country: 'United States',
    cities: 'Los Angeles, San Diego, San Jose, San Francisco, Seattle',
  },
  {
    timeOffset: 'UTC-8:00',
    timeZone: 'America/Tijuana',
    country: 'Mexico',
    cities: 'Tijuana, Mexicali, Ensenada, Rosarito, Tecate',
  },
  {
    timeOffset: 'UTC-8:00',
    timeZone: 'America/Vancouver',
    country: 'Canada',
    cities: 'Vancouver, Surrey, Okanagan, Victoria, Burnaby',
  },
  {
    timeOffset: 'UTC-8:00',
    timeZone: 'Pacific/Pitcairn',
    country: 'Pitcairn',
    cities: 'Adamstown',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Boise',
    country: 'United States',
    cities: 'Boise, Meridian, Nampa, Idaho Falls, Pocatello',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Cambridge_Bay',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Chihuahua',
    country: 'Mexico',
    cities:
      'Chihuahua, Ciudad Delicias, Cuauhtémoc, Parral, Nuevo Casas Grandes',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Creston',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Dawson',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Dawson_Creek',
    country: 'Canada',
    cities: 'Fort St. John, Dawson Creek',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Denver',
    country: 'United States',
    cities: 'Denver, El Paso, Albuquerque, Colorado Springs, Aurora',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Edmonton',
    country: 'Canada',
    cities: 'Calgary, Edmonton, Fort McMurray, Red Deer, Lethbridge',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Fort_Nelson',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Hermosillo',
    country: 'Mexico',
    cities:
      'Hermosillo, Ciudad Obregón, Nogales, San Luis Río Colorado, Navojoa',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Inuvik',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Mazatlan',
    country: 'Mexico',
    cities: 'Culiacán, Mazatlán, Tepic, Los Mochis, La Paz',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Ojinaga',
    country: 'Mexico',
    cities: 'Juárez, Manuel Ojinaga, Ojinaga',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Phoenix',
    country: 'United States',
    cities: 'Phoenix, Tucson, Mesa, Chandler, Gilbert',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Whitehorse',
    country: 'Canada',
    cities: 'Whitehorse',
  },
  {
    timeOffset: 'UTC-7:00',
    timeZone: 'America/Yellowknife',
    country: 'Canada',
    cities: 'Yellowknife',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Bahia_Banderas',
    country: 'Mexico',
    cities: 'Mezcales, San Vicente, Bucerías, Valle de Banderas',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Belize',
    country: 'Belize',
    cities: 'Belize City, San Ignacio, Orange Walk, Belmopan, Dangriga',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Chicago',
    country: 'United States',
    cities: 'Chicago, Houston, San Antonio, Dallas, Austin',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Costa_Rica',
    country: 'Costa Rica',
    cities: 'San José, Limón, San Francisco, Alajuela, Liberia',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/El_Salvador',
    country: 'El Salvador',
    cities: 'San Salvador, Soyapango, Santa Ana, San Miguel, Mejicanos',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Guatemala',
    country: 'Guatemala',
    cities: 'Guatemala City, Mixco, Villa Nueva, Petapa, San Juan Sacatepéquez',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Indiana/Knox',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Indiana/Tell_City',
    country: 'United States',
    cities: 'Tell City',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Managua',
    country: 'Nicaragua',
    cities: 'Managua, León, Masaya, Chinandega, Matagalpa',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Matamoros',
    country: 'Mexico',
    cities:
      'Reynosa, Heroica Matamoros, Nuevo Laredo, Piedras Negras, Ciudad Acuña',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Menominee',
    country: 'United States',
    cities: 'Menominee, Iron Mountain, Kingsford, Ironwood, Iron River',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Merida',
    country: 'Mexico',
    cities: 'Mérida, Campeche, Ciudad del Carmen, Kanasín, Valladolid',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Mexico_City',
    country: 'Mexico',
    cities: 'Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara, Puebla',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Monterrey',
    country: 'Mexico',
    cities: 'Monterrey, Saltillo, Guadalupe, Torreón, Victoria de Durango',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/North_Dakota/Beulah',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/North_Dakota/Center',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/North_Dakota/New_Salem',
    country: 'United States',
    cities: 'Mandan',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Rainy_River',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Rankin_Inlet',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Regina',
    country: 'Canada',
    cities: 'Saskatoon, Regina, Prince Albert, Moose Jaw, North Battleford',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Resolute',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Swift_Current',
    country: 'Canada',
    cities: 'Swift Current',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Tegucigalpa',
    country: 'Honduras',
    cities: 'Tegucigalpa, San Pedro Sula, Choloma, La Ceiba, El Progreso',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'America/Winnipeg',
    country: 'Canada',
    cities: 'Winnipeg, Brandon, Kenora, Portage la Prairie, Thompson',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'Pacific/Easter',
    country: 'Chile',
    cities: '',
  },
  {
    timeOffset: 'UTC-6:00',
    timeZone: 'Pacific/Galapagos',
    country: 'Ecuador',
    cities: 'Puerto Ayora, Puerto Baquerizo Moreno',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Atikokan',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Bogota',
    country: 'Colombia',
    cities: 'Bogotá, Cali, Medellín, Barranquilla, Cartagena',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Cancun',
    country: 'Mexico',
    cities:
      'Cancún, Chetumal, Playa del Carmen, Cozumel, Felipe Carrillo Puerto',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Cayman',
    country: 'Cayman Islands',
    cities: 'George Town, West Bay, Bodden Town, East End, North Side',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Detroit',
    country: 'United States',
    cities: 'Detroit, Grand Rapids, Warren, Sterling Heights, Ann Arbor',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Eirunepe',
    country: 'Brazil',
    cities: 'Eirunepé, Benjamin Constant, Envira',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Grand_Turk',
    country: 'Turks and Caicos Islands',
    cities: 'Cockburn Town',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Guayaquil',
    country: 'Ecuador',
    cities: 'Guayaquil, Quito, Cuenca, Santo Domingo de los Colorados, Machala',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Havana',
    country: 'Cuba',
    cities: 'Havana, Santiago de Cuba, Camagüey, Holguín, Guantánamo',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Indianapolis',
    country: 'United States',
    cities: 'Indianapolis, Fort Wayne, South Bend, Carmel, Bloomington',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Marengo',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Petersburg',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Vevay',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Vincennes',
    country: 'United States',
    cities: 'Vincennes, Jasper, Washington, Huntingburg',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Indiana/Winamac',
    country: 'United States',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Iqaluit',
    country: 'Canada',
    cities: 'Iqaluit',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Jamaica',
    country: 'Jamaica',
    cities: 'Kingston, New Kingston, Spanish Town, Portmore, Montego Bay',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Kentucky/Louisville',
    country: 'United States',
    cities:
      'Louisville, Jeffersonville, New Albany, Jeffersontown, Pleasure Ridge Park',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Kentucky/Monticello',
    country: 'United States',
    cities: 'Monticello',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Lima',
    country: 'Peru',
    cities: 'Lima, Arequipa, Callao, Trujillo, Chiclayo',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Nassau',
    country: 'Bahamas',
    cities: 'Nassau, Lucaya, Freeport, West End, Cooper’s Town',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/New_York',
    country: 'United States',
    cities: 'New York City, Brooklyn, Queens, Philadelphia, Manhattan',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Nipigon',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Panama',
    country: 'Panama',
    cities: 'Panamá, San Miguelito, Juan Díaz, David, Arraiján',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Pangnirtung',
    country: 'Canada',
    cities: '',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Port-au-Prince',
    country: 'Haiti',
    cities: 'Port-au-Prince, Carrefour, Delmas 73, Pétionville, Port-de-Paix',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Rio_Branco',
    country: 'Brazil',
    cities: 'Rio Branco, Cruzeiro do Sul, Sena Madureira, Tarauacá, Feijó',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Thunder_Bay',
    country: 'Canada',
    cities: 'Thunder Bay',
  },
  {
    timeOffset: 'UTC-5:00',
    timeZone: 'America/Toronto',
    country: 'Canada',
    cities: 'Toronto, Montréal, Ottawa, Mississauga, Québec',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Anguilla',
    country: 'Anguilla',
    cities:
      'The Valley, Blowing Point Village, Sandy Ground Village, The Quarter, Sandy Hill',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Antigua',
    country: 'Antigua and Barbuda',
    cities: 'Saint John’s, Piggotts, Bolands, Codrington, Parham',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Aruba',
    country: 'Aruba',
    cities: 'Oranjestad, Tanki Leendert, San Nicolas, Santa Cruz, Paradera',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Asuncion',
    country: 'Paraguay',
    cities: 'Asunción, Ciudad del Este, San Lorenzo, Capiatá, Lambaré',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Barbados',
    country: 'Barbados',
    cities: 'Bridgetown, Speightstown, Oistins, Bathsheba, Holetown',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Blanc-Sablon',
    country: 'Canada',
    cities: 'Lévis',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Boa_Vista',
    country: 'Brazil',
    cities: 'Boa Vista',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Campo_Grande',
    country: 'Brazil',
    cities: 'Campo Grande, Dourados, Corumbá, Três Lagoas, Ponta Porã',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Caracas',
    country: 'Venezuela',
    cities: 'Caracas, Maracaibo, Maracay, Valencia, Barquisimeto',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Cuiaba',
    country: 'Brazil',
    cities: 'Cuiabá, Várzea Grande, Rondonópolis, Sinop, Barra do Garças',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Curacao',
    country: 'Curacao',
    cities: 'Willemstad, Sint Michiel Liber',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Dominica',
    country: 'Dominica',
    cities: 'Roseau, Portsmouth, Berekua, Saint Joseph, Wesley',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Glace_Bay',
    country: 'Canada',
    cities: 'Sydney, Glace Bay, Sydney Mines',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Goose_Bay',
    country: 'Canada',
    cities: 'Labrador City, Happy Valley-Goose Bay',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Grenada',
    country: 'Grenada',
    cities: "Saint George's, Gouyave, Grenville, Victoria, Saint David’s",
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Guadeloupe',
    country: 'Guadeloupe',
    cities: 'Les Abymes, Baie-Mahault, Le Gosier, Petit-Bourg, Sainte-Anne',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Guyana',
    country: 'Guyana',
    cities: 'Georgetown, Linden, New Amsterdam, Anna Regina, Bartica',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Halifax',
    country: 'Canada',
    cities: 'Halifax, Dartmouth, Charlottetown, Lower Sackville, Truro',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Kralendijk',
    country: 'Bonaire, Saint Eustatius and Saba',
    cities: 'Kralendijk, Oranjestad, The Bottom',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/La_Paz',
    country: 'Bolivia',
    cities: 'Santa Cruz de la Sierra, Cochabamba, La Paz, Sucre, Oruro',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Lower_Princes',
    country: 'Sint Maarten',
    cities: 'Cul de Sac, Lower Prince’s Quarter, Koolbaai, Philipsburg',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Manaus',
    country: 'Brazil',
    cities: 'Manaus, Itacoatiara, Parintins, Manacapuru, Coari',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Marigot',
    country: 'Saint Martin',
    cities: 'Marigot',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Martinique',
    country: 'Martinique',
    cities: 'Fort-de-France, Le Lamentin, Le Robert, Sainte-Marie, Le François',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Moncton',
    country: 'Canada',
    cities: 'Moncton, Saint John, Fredericton, Dieppe, Miramichi',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Montserrat',
    country: 'Montserrat',
    cities: 'Brades, Saint Peters, Plymouth',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Porto_Velho',
    country: 'Brazil',
    cities: 'Porto Velho, Ji Paraná, Vilhena, Ariquemes, Cacoal',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Port_of_Spain',
    country: 'Trinidad and Tobago',
    cities: 'Chaguanas, Mon Repos, San Fernando, Port of Spain, Rio Claro',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Puerto_Rico',
    country: 'Puerto Rico',
    cities: 'San Juan, Bayamón, Carolina, Ponce, Caguas',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Santiago',
    country: 'Chile',
    cities: 'Santiago, Puente Alto, Antofagasta, Viña del Mar, Valparaíso',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Santo_Domingo',
    country: 'Dominican Republic',
    cities:
      'Santo Domingo, Santiago de los Caballeros, Santo Domingo Oeste, Santo Domingo Este, San Pedro de Macorís',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/St_Barthelemy',
    country: 'Saint Barthelemy',
    cities: 'Gustavia',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/St_Kitts',
    country: 'Saint Kitts and Nevis',
    cities: 'Basseterre, Fig Tree, Market Shop, Saint Paul’s, Middle Island',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/St_Lucia',
    country: 'Saint Lucia',
    cities: 'Castries, Bisee, Vieux Fort, Micoud, Soufrière',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/St_Thomas',
    country: 'U.S. Virgin Islands',
    cities: 'Saint Croix, Charlotte Amalie, Cruz Bay',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/St_Vincent',
    country: 'Saint Vincent and the Grenadines',
    cities:
      'Kingstown, Kingstown Park, Georgetown, Barrouallie, Port Elizabeth',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Thule',
    country: 'Greenland',
    cities: '',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'America/Tortola',
    country: 'British Virgin Islands',
    cities: 'Road Town',
  },
  {
    timeOffset: 'UTC-4:00',
    timeZone: 'Atlantic/Bermuda',
    country: 'Bermuda',
    cities: 'Hamilton',
  },
  {
    timeOffset: 'UTC-3:30',
    timeZone: 'America/St_Johns',
    country: 'Canada',
    cities:
      "St. John's, Mount Pearl, Corner Brook, Conception Bay South, Bay Roberts",
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Araguaina',
    country: 'Brazil',
    cities: 'Palmas, Araguaína, Gurupi, Miracema do Tocantins, Porto Franco',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Buenos_Aires',
    country: 'Argentina',
    cities: 'Buenos Aires, La Plata, Mar del Plata, Morón, Bahía Blanca',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Catamarca',
    country: 'Argentina',
    cities:
      'San Fernando del Valle de Catamarca, Trelew, Puerto Madryn, Esquel, Rawson',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Cordoba',
    country: 'Argentina',
    cities: 'Córdoba, Rosario, Santa Fe, Resistencia, Santiago del Estero',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Jujuy',
    country: 'Argentina',
    cities:
      'San Salvador de Jujuy, San Pedro de Jujuy, Libertador General San Martín, Palpalá, La Quiaca',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/La_Rioja',
    country: 'Argentina',
    cities: 'La Rioja, Chilecito, Arauco, Chamical',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Mendoza',
    country: 'Argentina',
    cities: 'Mendoza, San Rafael, San Martín',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Rio_Gallegos',
    country: 'Argentina',
    cities:
      'Comodoro Rivadavia, Río Gallegos, Caleta Olivia, Pico Truncado, Puerto Deseado',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Salta',
    country: 'Argentina',
    cities: 'Salta, Neuquén, Santa Rosa, San Carlos de Bariloche, Cipolletti',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/San_Juan',
    country: 'Argentina',
    cities: 'San Juan, Chimbas, Santa Lucía, Pocito, Caucete',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/San_Luis',
    country: 'Argentina',
    cities: 'San Luis, Villa Mercedes, La Punta, Merlo, Justo Daract',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Tucuman',
    country: 'Argentina',
    cities:
      'San Miguel de Tucumán, Yerba Buena, Tafí Viejo, Alderetes, Aguilares',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Argentina/Ushuaia',
    country: 'Argentina',
    cities: 'Ushuaia, Río Grande',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Bahia',
    country: 'Brazil',
    cities:
      'Salvador, Feira de Santana, Vitória da Conquista, Itabuna, Camaçari',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Belem',
    country: 'Brazil',
    cities: 'Belém, Ananindeua, Macapá, Parauapebas, Marabá',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Cayenne',
    country: 'French Guiana',
    cities:
      'Cayenne, Matoury, Saint-Laurent-du-Maroni, Kourou, Rémire-Montjoly',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Fortaleza',
    country: 'Brazil',
    cities: 'Fortaleza, São Luís, Natal, Teresina, João Pessoa',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Godthab',
    country: 'Greenland',
    cities: 'Nuuk, Sisimiut, Ilulissat, Qaqortoq, Aasiaat',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Maceio',
    country: 'Brazil',
    cities:
      'Maceió, Aracaju, Arapiraca, Nossa Senhora do Socorro, São Cristóvão',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Miquelon',
    country: 'Saint Pierre and Miquelon',
    cities: 'Saint-Pierre, Miquelon',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Montevideo',
    country: 'Uruguay',
    cities: 'Montevideo, Salto, Paysandú, Las Piedras, Rivera',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Paramaribo',
    country: 'Suriname',
    cities: 'Paramaribo, Lelydorp, Brokopondo, Nieuw Nickerie, Moengo',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Punta_Arenas',
    country: 'Chile',
    cities: 'Punta Arenas, Puerto Natales',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Recife',
    country: 'Brazil',
    cities: 'Recife, Jaboatão, Jaboatão dos Guararapes, Olinda, Paulista',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Santarem',
    country: 'Brazil',
    cities: 'Santarém, Altamira, Itaituba, Oriximiná, Alenquer',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'America/Sao_Paulo',
    country: 'Brazil',
    cities: 'São Paulo, Rio de Janeiro, Belo Horizonte, Brasília, Curitiba',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'Antarctica/Palmer',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'Antarctica/Rothera',
    country: 'Antarctica',
    cities: 'La Legua-San Jacinto',
  },
  {
    timeOffset: 'UTC-3:00',
    timeZone: 'Atlantic/Stanley',
    country: 'Falkland Islands',
    cities: 'Stanley',
  },
  {
    timeOffset: 'UTC-2:00',
    timeZone: 'America/Noronha',
    country: 'Brazil',
    cities: 'Itamaracá',
  },
  {
    timeOffset: 'UTC-2:00',
    timeZone: 'Atlantic/South_Georgia',
    country: 'South Georgia and the South Sandwich Islands',
    cities: 'Grytviken',
  },
  {
    timeOffset: 'UTC-1:00',
    timeZone: 'America/Scoresbysund',
    country: 'Greenland',
    cities: '',
  },
  {
    timeOffset: 'UTC-1:00',
    timeZone: 'Atlantic/Azores',
    country: 'Portugal',
    cities:
      'Ponta Delgada, Lagoa, Angra do Heroísmo, Rosto de Cão, Rabo de Peixe',
  },
  {
    timeOffset: 'UTC-1:00',
    timeZone: 'Atlantic/Cape_Verde',
    country: 'Cabo Verde',
    cities: 'Praia, Mindelo, Santa Maria, Cova Figueira, Santa Cruz',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Abidjan',
    country: 'Ivory Coast',
    cities: 'Abidjan, Abobo, Bouaké, Daloa, San-Pédro',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Accra',
    country: 'Ghana',
    cities: 'Accra, Kumasi, Tamale, Takoradi, Atsiaman',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Bamako',
    country: 'Mali',
    cities: 'Bamako, Sikasso, Mopti, Koutiala, Ségou',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Banjul',
    country: 'Gambia',
    cities: 'Serekunda, Brikama, Bakau, Banjul, Farafenni',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Bissau',
    country: 'Guinea-Bissau',
    cities: 'Bissau, Bafatá, Gabú, Bissorã, Bolama',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Conakry',
    country: 'Guinea',
    cities: 'Camayenne, Conakry, Nzérékoré, Kindia, Kankan',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Dakar',
    country: 'Senegal',
    cities: 'Dakar, Pikine, Touba, Thiès, Thiès Nones',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Freetown',
    country: 'Sierra Leone',
    cities: 'Freetown, Bo, Kenema, Koidu, Makeni',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Lome',
    country: 'Togo',
    cities: 'Lomé, Sokodé, Kara, Atakpamé, Kpalimé',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Monrovia',
    country: 'Liberia',
    cities: 'Monrovia, Gbarnga, Kakata, Bensonville, Harper',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Nouakchott',
    country: 'Mauritania',
    cities: 'Nouakchott, Nouadhibou, Néma, Kaédi, Rosso',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Ouagadougou',
    country: 'Burkina Faso',
    cities: 'Ouagadougou, Bobo-Dioulasso, Koudougou, Ouahigouya, Banfora',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Africa/Sao_Tome',
    country: 'Sao Tome and Principe',
    cities: 'São Tomé, Santo António',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'America/Danmarkshavn',
    country: 'Greenland',
    cities: '',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Antarctica/Troll',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Atlantic/Canary',
    country: 'Spain',
    cities:
      'Las Palmas de Gran Canaria, Santa Cruz de Tenerife, La Laguna, Telde, Arona',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Atlantic/Faroe',
    country: 'Faroe Islands',
    cities: 'Tórshavn, Klaksvík, Fuglafjørður, Tvøroyri, Miðvágur',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Atlantic/Madeira',
    country: 'Portugal',
    cities: 'Funchal, Câmara de Lobos, São Martinho, Caniço, Machico',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Atlantic/Reykjavik',
    country: 'Iceland',
    cities: 'Reykjavík, Kópavogur, Hafnarfjörður, Akureyri, Garðabær',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Atlantic/St_Helena',
    country: 'Saint Helena',
    cities: 'Jamestown, Georgetown, Edinburgh of the Seven Seas',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Europe/Guernsey',
    country: 'Guernsey',
    cities:
      'Saint Peter Port, St Martin, Saint Sampson, St Anne, Saint Saviour',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Europe/Isle_of_Man',
    country: 'Isle of Man',
    cities: 'Douglas, Ramsey, Peel, Port Erin, Castletown',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Europe/Jersey',
    country: 'Jersey',
    cities: 'Saint Helier, Le Hocq',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Europe/Lisbon',
    country: 'Portugal',
    cities: 'Lisbon, Porto, Amadora, Braga, Setúbal',
  },
  {
    timeOffset: 'UTC+0:00',
    timeZone: 'Europe/London',
    country: 'United Kingdom',
    cities: 'London, Birmingham, Liverpool, Nottingham, Sheffield',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Algiers',
    country: 'Algeria',
    cities: 'Algiers, Boumerdas, Oran, Tébessa, Constantine',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Bangui',
    country: 'Central African Republic',
    cities: 'Bangui, Bimbo, Mbaïki, Berbérati, Kaga Bandoro',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Brazzaville',
    country: 'Republic of the Congo',
    cities: 'Brazzaville, Pointe-Noire, Dolisie, Kayes, Owando',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Casablanca',
    country: 'Morocco',
    cities: 'Casablanca, Rabat, Fès, Sale, Marrakesh',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Ceuta',
    country: 'Spain',
    cities: 'Ceuta, Melilla',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Douala',
    country: 'Cameroon',
    cities: 'Douala, Yaoundé, Garoua, Kousséri, Bamenda',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/El_Aaiun',
    country: 'Western Sahara',
    cities: 'Laayoune, Dakhla, Laayoune Plage',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Kinshasa',
    country: 'Democratic Republic of the Congo',
    cities: 'Kinshasa, Masina, Kikwit, Mbandaka, Matadi',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Lagos',
    country: 'Nigeria',
    cities: 'Lagos, Kano, Ibadan, Kaduna, Port Harcourt',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Libreville',
    country: 'Gabon',
    cities: 'Libreville, Port-Gentil, Franceville, Oyem, Moanda',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Luanda',
    country: 'Angola',
    cities: 'Luanda, N’dalatando, Huambo, Lobito, Benguela',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Malabo',
    country: 'Equatorial Guinea',
    cities: 'Bata, Malabo, Ebebiyin, Aconibe, Añisoc',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Ndjamena',
    country: 'Chad',
    cities: "N'Djamena, Moundou, Sarh, Abéché, Kelo",
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Niamey',
    country: 'Niger',
    cities: 'Niamey, Zinder, Maradi, Agadez, Alaghsas',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Porto-Novo',
    country: 'Benin',
    cities: 'Cotonou, Abomey-Calavi, Djougou, Porto-Novo, Parakou',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Africa/Tunis',
    country: 'Tunisia',
    cities: 'Tunis, Sfax, Sousse, Kairouan, Bizerte',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Arctic/Longyearbyen',
    country: 'Svalbard and Jan Mayen',
    cities: 'Longyearbyen, Olonkinbyen',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Amsterdam',
    country: 'Netherlands',
    cities: 'Amsterdam, Rotterdam, The Hague, Utrecht, Eindhoven',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Andorra',
    country: 'Andorra',
    cities:
      'Andorra la Vella, les Escaldes, Encamp, Sant Julià de Lòria, la Massana',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Belgrade',
    country: 'Serbia',
    cities: 'Belgrade, Pristina, Niš, Novi Sad, Prizren',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Berlin',
    country: 'Germany',
    cities: 'Berlin, Hamburg, Munich, Köln, Frankfurt am Main',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Bratislava',
    country: 'Slovakia',
    cities: 'Bratislava, Košice, Prešov, Nitra, Žilina',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Brussels',
    country: 'Belgium',
    cities: 'Brussels, Antwerpen, Gent, Charleroi, Liège',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Budapest',
    country: 'Hungary',
    cities: 'Budapest, Debrecen, Miskolc, Szeged, Pécs',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Copenhagen',
    country: 'Denmark',
    cities: 'Copenhagen, Århus, Odense, Aalborg, Frederiksberg',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Dublin',
    country: 'Ireland',
    cities: 'Dublin, Cork, Luimneach, Gaillimh, Tallaght',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Gibraltar',
    country: 'Gibraltar',
    cities: 'Gibraltar',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Ljubljana',
    country: 'Slovenia',
    cities: 'Ljubljana, Maribor, Celje, Kranj, Velenje',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Luxembourg',
    country: 'Luxembourg',
    cities: 'Luxembourg, Esch-sur-Alzette, Dudelange, Schifflange, Bettembourg',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Madrid',
    country: 'Spain',
    cities: 'Madrid, Barcelona, Valencia, Sevilla, Zaragoza',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Malta',
    country: 'Malta',
    cities: 'Birkirkara, Qormi, Mosta, Żabbar, San Pawl il-Baħar',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Monaco',
    country: 'Monaco',
    cities: 'Monaco, Monte-Carlo, La Condamine',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Oslo',
    country: 'Norway',
    cities: 'Oslo, Bergen, Trondheim, Stavanger, Drammen',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Paris',
    country: 'France',
    cities: 'Paris, Marseille, Lyon, Toulouse, Nice',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Podgorica',
    country: 'Montenegro',
    cities: 'Podgorica, Nikšić, Herceg Novi, Pljevlja, Budva',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Prague',
    country: 'Czechia',
    cities: 'Prague, Brno, Ostrava, Pilsen, Olomouc',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Rome',
    country: 'Italy',
    cities: 'Rome, Milan, Naples, Turin, Palermo',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/San_Marino',
    country: 'San Marino',
    cities: 'Serravalle, Borgo Maggiore, San Marino, Domagnano, Fiorentino',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Sarajevo',
    country: 'Bosnia and Herzegovina',
    cities: 'Sarajevo, Banja Luka, Zenica, Tuzla, Mostar',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Skopje',
    country: 'North Macedonia',
    cities: 'Skopje, Bitola, Kumanovo, Prilep, Tetovo',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Stockholm',
    country: 'Sweden',
    cities: 'Stockholm, Göteborg, Malmö, Uppsala, Sollentuna',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Tirane',
    country: 'Albania',
    cities: 'Tirana, Durrës, Elbasan, Vlorë, Shkodër',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Vaduz',
    country: 'Liechtenstein',
    cities: 'Schaan, Vaduz, Triesen, Balzers, Eschen',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Vatican',
    country: 'Vatican',
    cities: 'Vatican City',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Vienna',
    country: 'Austria',
    cities: 'Vienna, Graz, Linz, Favoriten, Donaustadt',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Warsaw',
    country: 'Poland',
    cities: 'Warsaw, Łódź, Kraków, Wrocław, Poznań',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Zagreb',
    country: 'Croatia',
    cities: 'Zagreb, Split, Rijeka, Osijek, Zadar',
  },
  {
    timeOffset: 'UTC+1:00',
    timeZone: 'Europe/Zurich',
    country: 'Switzerland',
    cities: 'Zürich, Genève, Basel, Bern, Lausanne',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Blantyre',
    country: 'Malawi',
    cities: 'Lilongwe, Blantyre, Mzuzu, Zomba, Kasungu',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Bujumbura',
    country: 'Burundi',
    cities: 'Bujumbura, Muyinga, Gitega, Ruyigi, Ngozi',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Cairo',
    country: 'Egypt',
    cities: 'Cairo, Alexandria, Giza, Port Said, Suez',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Gaborone',
    country: 'Botswana',
    cities: 'Gaborone, Francistown, Molepolole, Selebi-Phikwe, Maun',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Harare',
    country: 'Zimbabwe',
    cities: 'Harare, Bulawayo, Chitungwiza, Mutare, Gweru',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Johannesburg',
    country: 'South Africa',
    cities: 'Cape Town, Durban, Johannesburg, Soweto, Pretoria',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Khartoum',
    country: 'Sudan',
    cities: 'Khartoum, Omdurman, Nyala, Port Sudan, Kassala',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Kigali',
    country: 'Rwanda',
    cities: 'Kigali, Butare, Gitarama, Musanze, Gisenyi',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Lubumbashi',
    country: 'Democratic Republic of the Congo',
    cities: 'Lubumbashi, Mbuji-Mayi, Kisangani, Kananga, Likasi',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Lusaka',
    country: 'Zambia',
    cities: 'Lusaka, Kitwe, Ndola, Kabwe, Chingola',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Maputo',
    country: 'Mozambique',
    cities: 'Maputo, Matola, Beira, Nampula, Chimoio',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Maseru',
    country: 'Lesotho',
    cities: 'Maseru, Mafeteng, Leribe, Maputsoe, Mohale’s Hoek',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Mbabane',
    country: 'Eswatini',
    cities: 'Manzini, Mbabane, Big Bend, Malkerns, Nhlangano',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Tripoli',
    country: 'Libya',
    cities: 'Tripoli, Benghazi, Mişrātah, Tarhuna, Al Khums',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Africa/Windhoek',
    country: 'Namibia',
    cities: 'Windhoek, Rundu, Walvis Bay, Oshakati, Swakopmund',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Amman',
    country: 'Jordan',
    cities: 'Amman, Zarqa, Irbid, Russeifa, Wādī as Sīr',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Beirut',
    country: 'Lebanon',
    cities: 'Beirut, Ra’s Bayrūt, Tripoli, Sidon, Tyre',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Damascus',
    country: 'Syria',
    cities: 'Aleppo, Damascus, Homs, Ḩamāh, Latakia',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Famagusta',
    country: 'Cyprus',
    cities: 'Famagusta, Kyrenia, Protaras, Paralímni, Lápithos',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Gaza',
    country: 'Palestinian Territory',
    cities: 'Gaza, Khān Yūnis, Jabālyā, Rafaḩ, Dayr al Balaḩ',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Hebron',
    country: 'Palestinian Territory',
    cities: 'East Jerusalem, Hebron, Nablus, Battir, Ţūlkarm',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Jerusalem',
    country: 'Israel',
    cities: 'Jerusalem, Tel Aviv, West Jerusalem, Haifa, Ashdod',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Asia/Nicosia',
    country: 'Cyprus',
    cities: 'Nicosia, Limassol, Larnaca, Stróvolos, Paphos',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Athens',
    country: 'Greece',
    cities: 'Athens, Thessaloníki, Pátra, Piraeus, Lárisa',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Bucharest',
    country: 'Romania',
    cities: 'Bucharest, Sector 3, Sector 6, Sector 2, Iaşi',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Chisinau',
    country: 'Moldova',
    cities: 'Chisinau, Tiraspol, Bălţi, Bender, Rîbniţa',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Helsinki',
    country: 'Finland',
    cities: 'Helsinki, Espoo, Tampere, Vantaa, Turku',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Kaliningrad',
    country: 'Russia',
    cities: 'Kaliningrad, Chernyakhovsk, Sovetsk, Baltiysk, Gusev',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Kiev',
    country: 'Ukraine',
    cities: 'Kyiv, Kharkiv, Dnipro, Donetsk, Odessa',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Mariehamn',
    country: 'Aland Islands',
    cities: 'Mariehamn',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Riga',
    country: 'Latvia',
    cities: 'Riga, Daugavpils, Liepāja, Jelgava, Jūrmala',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Sofia',
    country: 'Bulgaria',
    cities: 'Sofia, Plovdiv, Varna, Burgas, Ruse',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Tallinn',
    country: 'Estonia',
    cities: 'Tallinn, Tartu, Narva, Kohtla-Järve, Pärnu',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Uzhgorod',
    country: 'Ukraine',
    cities: 'Uzhgorod, Mukachevo, Khust, Berehove, Tyachiv',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Vilnius',
    country: 'Lithuania',
    cities: 'Vilnius, Kaunas, Klaipėda, Šiauliai, Panevėžys',
  },
  {
    timeOffset: 'UTC+2:00',
    timeZone: 'Europe/Zaporozhye',
    country: 'Ukraine',
    cities: 'Zaporizhia, Luhansk, Sevastopol, Melitopol, Syevyerodonets’k',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Addis_Ababa',
    country: 'Ethiopia',
    cities: "Addis Ababa, Dire Dawa, Mek'ele, Nazrēt, Bahir Dar",
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Asmara',
    country: 'Eritrea',
    cities: 'Asmara, Keren, Massawa, Assab, Mendefera',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Dar_es_Salaam',
    country: 'Tanzania',
    cities: 'Dar es Salaam, Mwanza, Zanzibar, Arusha, Mbeya',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Djibouti',
    country: 'Djibouti',
    cities: "Djibouti, 'Ali Sabieh, Tadjourah, Obock, Dikhil",
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Juba',
    country: 'South Sudan',
    cities: 'Juba, Winejok, Malakal, Wau, Pajok',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Kampala',
    country: 'Uganda',
    cities: 'Kampala, Gulu, Lira, Mbarara, Jinja',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Mogadishu',
    country: 'Somalia',
    cities: 'Mogadishu, Hargeysa, Berbera, Kismayo, Marka',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Africa/Nairobi',
    country: 'Kenya',
    cities: 'Nairobi, Mombasa, Nakuru, Eldoret, Kisumu',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Antarctica/Syowa',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Aden',
    country: 'Yemen',
    cities: 'Sanaa, Al Ḩudaydah, Ta‘izz, Aden, Mukalla',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Baghdad',
    country: 'Iraq',
    cities:
      'Baghdad, Basrah, Al Mawşil al Jadīdah, Al Başrah al Qadīmah, Mosul',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Bahrain',
    country: 'Bahrain',
    cities: 'Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb, Madīnat Ḩamad',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Kuwait',
    country: 'Kuwait',
    cities: 'Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim, Al Farwānīyah',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Qatar',
    country: 'Qatar',
    cities: 'Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah, Al Khawr',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Asia/Riyadh',
    country: 'Saudi Arabia',
    cities: 'Riyadh, Jeddah, Mecca, Medina, Sulţānah',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Europe/Istanbul',
    country: 'Turkey',
    cities: 'Istanbul, Ankara, İzmir, Bursa, Adana',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Europe/Kirov',
    country: 'Russia',
    cities: 'Kirov, Kirovo-Chepetsk, Vyatskiye Polyany, Slobodskoy, Kotel’nich',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Europe/Minsk',
    country: 'Belarus',
    cities: "Minsk, Homyel', Mahilyow, Vitebsk, Hrodna",
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Europe/Moscow',
    country: 'Russia',
    cities: 'Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Europe/Simferopol',
    country: 'Ukraine',
    cities: 'Simferopol, Kerch, Yevpatoriya, Yalta, Feodosiya',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Indian/Antananarivo',
    country: 'Madagascar',
    cities: 'Antananarivo, Toamasina, Antsirabe, Fianarantsoa, Mahajanga',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Indian/Comoro',
    country: 'Comoros',
    cities: 'Moroni, Moutsamoudou, Fomboni, Domoni, Tsimbeo',
  },
  {
    timeOffset: 'UTC+3:00',
    timeZone: 'Indian/Mayotte',
    country: 'Mayotte',
    cities: 'Mamoudzou, Koungou, Dzaoudzi, Dembeni, Sada',
  },
  {
    timeOffset: 'UTC+3:30',
    timeZone: 'Asia/Tehran',
    country: 'Iran',
    cities: 'Tehran, Mashhad, Isfahan, Karaj, Tabriz',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Asia/Baku',
    country: 'Azerbaijan',
    cities: 'Baku, Ganja, Sumqayıt, Lankaran, Yevlakh',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Asia/Dubai',
    country: 'United Arab Emirates',
    cities: 'Dubai, Sharjah, Abu Dhabi, Ajman City, Ras Al Khaimah City',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Asia/Muscat',
    country: 'Oman',
    cities: 'Muscat, Seeb, Şalālah, Bawshar, Sohar',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Asia/Tbilisi',
    country: 'Georgia',
    cities: 'Tbilisi, Kutaisi, Batumi, Sokhumi, Zugdidi',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Asia/Yerevan',
    country: 'Armenia',
    cities: 'Yerevan, Gyumri, Vanadzor, Vagharshapat, Hrazdan',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Europe/Astrakhan',
    country: 'Russia',
    cities: 'Astrakhan, Akhtubinsk, Znamensk, Kharabali, Kamyzyak',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Europe/Samara',
    country: 'Russia',
    cities: 'Samara, Tol’yatti, Izhevsk, Syzran’, Novokuybyshevsk',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Europe/Saratov',
    country: 'Russia',
    cities: 'Saratov, Balakovo, Engel’s, Balashov, Vol’sk',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Europe/Ulyanovsk',
    country: 'Russia',
    cities: 'Ulyanovsk, Dimitrovgrad, Inza, Barysh, Novoul’yanovsk',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Europe/Volgograd',
    country: 'Russia',
    cities: 'Volgograd, Volzhskiy, Kamyshin, Mikhaylovka, Uryupinsk',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Indian/Mahe',
    country: 'Seychelles',
    cities: 'Victoria, Anse Boileau, Bel Ombre, Beau Vallon, Cascade',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Indian/Mauritius',
    country: 'Mauritius',
    cities:
      'Port Louis, Beau Bassin-Rose Hill, Vacoas, Curepipe, Quatre Bornes',
  },
  {
    timeOffset: 'UTC+4:00',
    timeZone: 'Indian/Reunion',
    country: 'Reunion',
    cities: 'Saint-Denis, Saint-Paul, Saint-Pierre, Le Tampon, Saint-André',
  },
  {
    timeOffset: 'UTC+4:30',
    timeZone: 'Asia/Kabul',
    country: 'Afghanistan',
    cities: 'Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Antarctica/Mawson',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Aqtau',
    country: 'Kazakhstan',
    cities: 'Shevchenko, Zhanaozen, Beyneu, Shetpe, Yeraliyev',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Aqtobe',
    country: 'Kazakhstan',
    cities: 'Aktobe, Kandyagash, Shalqar, Khromtau, Embi',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Ashgabat',
    country: 'Turkmenistan',
    cities: 'Ashgabat, Türkmenabat, Daşoguz, Mary, Balkanabat',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Atyrau',
    country: 'Kazakhstan',
    cities: 'Atyrau, Qulsary, Shalkar, Balykshi, Maqat',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Dushanbe',
    country: 'Tajikistan',
    cities: 'Dushanbe, Khujand, Kŭlob, Bokhtar, Istaravshan',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Karachi',
    country: 'Pakistan',
    cities: 'Karachi, Lahore, Faisalabad, Rawalpindi, Multan',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Oral',
    country: 'Kazakhstan',
    cities: 'Oral, Aqsay, Zhänibek, Tasqala, Zhumysker',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Qyzylorda',
    country: 'Kazakhstan',
    cities: 'Kyzylorda, Baikonur, Novokazalinsk, Aral, Chiili',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Samarkand',
    country: 'Uzbekistan',
    cities: 'Samarkand, Bukhara, Nukus, Qarshi, Jizzax',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Tashkent',
    country: 'Uzbekistan',
    cities: 'Tashkent, Namangan, Andijon, Qo‘qon, Chirchiq',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Asia/Yekaterinburg',
    country: 'Russia',
    cities: 'Yekaterinburg, Chelyabinsk, Ufa, Perm, Orenburg',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Indian/Kerguelen',
    country: 'French Southern Territories',
    cities: 'Port-aux-Français',
  },
  {
    timeOffset: 'UTC+5:00',
    timeZone: 'Indian/Maldives',
    country: 'Maldives',
    cities: 'Male, Fuvahmulah, Hithadhoo, Kulhudhuffushi, Thinadhoo',
  },
  {
    timeOffset: 'UTC+5:30',
    timeZone: 'Asia/Colombo',
    country: 'Sri Lanka',
    cities: 'Colombo, Dehiwala-Mount Lavinia, Moratuwa, Jaffna, Negombo',
  },
  {
    timeOffset: 'UTC+5:30',
    timeZone: 'Asia/Kolkata',
    country: 'India',
    cities: 'Mumbai, Delhi, Bengaluru, Kolkāta, Chennai',
  },
  {
    timeOffset: 'UTC+5:45',
    timeZone: 'Asia/Kathmandu',
    country: 'Nepal',
    cities: 'Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Antarctica/Vostok',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Almaty',
    country: 'Kazakhstan',
    cities: 'Almaty, Karagandy, Shymkent, Taraz, Nur-Sultan',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Bishkek',
    country: 'Kyrgyzstan',
    cities: 'Bishkek, Osh, Jalal-Abad, Karakol, Tokmok',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Dhaka',
    country: 'Bangladesh',
    cities: 'Dhaka, Chittagong, Khulna, Rājshāhi, Comilla',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Omsk',
    country: 'Russia',
    cities: 'Omsk, Tara, Kalachinsk, Znamenskoye, Tavricheskoye',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Qostanay',
    country: 'Kazakhstan',
    cities: 'Kostanay, Rudnyy, Dzhetygara, Arkalyk, Lisakovsk',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Thimphu',
    country: 'Bhutan',
    cities: 'Thimphu, Punākha, Tsirang, Phuntsholing, Pemagatshel',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Asia/Urumqi',
    country: 'China',
    cities: 'Zhongshan, Ürümqi, Zhanjiang, Shihezi, Huocheng',
  },
  {
    timeOffset: 'UTC+6:00',
    timeZone: 'Indian/Chagos',
    country: 'British Indian Ocean Territory',
    cities: '',
  },
  {
    timeOffset: 'UTC+6:30',
    timeZone: 'Asia/Yangon',
    country: 'Myanmar',
    cities: 'Yangon, Mandalay, Nay Pyi Taw, Mawlamyine, Kyain Seikgyi Township',
  },
  {
    timeOffset: 'UTC+6:30',
    timeZone: 'Indian/Cocos',
    country: 'Cocos Islands',
    cities: 'West Island',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Antarctica/Davis',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Bangkok',
    country: 'Thailand',
    cities: 'Bangkok, Samut Prakan, Mueang Nonthaburi, Udon Thani, Chon Buri',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Barnaul',
    country: 'Russia',
    cities: 'Barnaul, Biysk, Rubtsovsk, Novoaltaysk, Gorno-Altaysk',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Hovd',
    country: 'Mongolia',
    cities: 'Khovd, Ölgii, Ulaangom, Uliastay, Altai',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Ho_Chi_Minh',
    country: 'Vietnam',
    cities: 'Ho Chi Minh City, Hanoi, Da Nang, Haiphong, Biên Hòa',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Jakarta',
    country: 'Indonesia',
    cities: 'Jakarta, Surabaya, Medan, Bandung, Bekasi',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Krasnoyarsk',
    country: 'Russia',
    cities: 'Krasnoyarsk, Abakan, Norilsk, Achinsk, Kyzyl',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Novokuznetsk',
    country: 'Russia',
    cities:
      'Novokuznetsk, Kemerovo, Prokop’yevsk, Leninsk-Kuznetsky, Kiselëvsk',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Novosibirsk',
    country: 'Russia',
    cities: 'Novosibirsk, Berdsk, Iskitim, Akademgorodok, Kuybyshev',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Phnom_Penh',
    country: 'Cambodia',
    cities: 'Phnom Penh, Takeo, Sihanoukville, Battambang, Siem Reap',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Pontianak',
    country: 'Indonesia',
    cities: 'Pontianak, Tanjung Pinang, Palangkaraya, Singkawang, Sampit',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Tomsk',
    country: 'Russia',
    cities: 'Tomsk, Seversk, Strezhevoy, Kolpashevo, Asino',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Asia/Vientiane',
    country: 'Laos',
    cities: 'Vientiane, Pakse, Thakhèk, Savannakhet, Luang Prabang',
  },
  {
    timeOffset: 'UTC+7:00',
    timeZone: 'Indian/Christmas',
    country: 'Christmas Island',
    cities: 'Flying Fish Cove',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Antarctica/Casey',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Brunei',
    country: 'Brunei',
    cities: 'Bandar Seri Begawan, Kuala Belait, Seria, Tutong, Bangar',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Choibalsan',
    country: 'Mongolia',
    cities: 'Baruun-Urt, Choibalsan',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Hong_Kong',
    country: 'Hong Kong',
    cities: 'Hong Kong, Kowloon, Tsuen Wan, Yuen Long Kau Hui, Tung Chung',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Irkutsk',
    country: 'Russia',
    cities: 'Irkutsk, Ulan-Ude, Bratsk, Angarsk, Ust’-Ilimsk',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Kuala_Lumpur',
    country: 'Malaysia',
    cities: 'Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang, Johor Bahru',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Kuching',
    country: 'Malaysia',
    cities: 'Kuching, Kota Kinabalu, Sandakan, Tawau, Miri',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Macau',
    country: 'Macao',
    cities: 'Macau',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Makassar',
    country: 'Indonesia',
    cities: 'Makassar, Denpasar, City of Balikpapan, Banjarmasin, Manado',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Manila',
    country: 'Philippines',
    cities: 'Quezon City, Manila, Caloocan City, Budta, Davao',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Shanghai',
    country: 'China',
    cities: 'Shanghai, Beijing, Tianjin, Guangzhou, Shenzhen',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Singapore',
    country: 'Singapore',
    cities: 'Singapore, Woodlands',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Taipei',
    country: 'Taiwan',
    cities: 'Taipei, Kaohsiung, Taichung, Tainan, Banqiao',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Asia/Ulaanbaatar',
    country: 'Mongolia',
    cities: 'Ulan Bator, Erdenet, Darhan, Hovd, Murun-kuren',
  },
  {
    timeOffset: 'UTC+8:00',
    timeZone: 'Australia/Perth',
    country: 'Australia',
    cities: 'Perth, Rockingham, Mandurah, Bunbury, Albany',
  },
  {
    timeOffset: 'UTC+8:45',
    timeZone: 'Australia/Eucla',
    country: 'Australia',
    cities: '',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Chita',
    country: 'Russia',
    cities: 'Chita, Krasnokamensk, Borzya, Petrovsk-Zabaykal’skiy, Aginskoye',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Dili',
    country: 'Timor Leste',
    cities: 'Dili, Maliana, Suai, Likisá, Aileu',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Jayapura',
    country: 'Indonesia',
    cities: 'Ambon, Jayapura, Sorong, Ternate, Abepura',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Khandyga',
    country: 'Russia',
    cities: '',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Pyongyang',
    country: 'North Korea',
    cities: 'Pyongyang, Hamhŭng, Namp’o, Sunch’ŏn, Hŭngnam',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Seoul',
    country: 'South Korea',
    cities: 'Seoul, Busan, Incheon, Daegu, Daejeon',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Tokyo',
    country: 'Japan',
    cities: 'Tokyo, Yokohama, Osaka, Nagoya, Sapporo',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Asia/Yakutsk',
    country: 'Russia',
    cities: 'Yakutsk, Blagoveshchensk, Belogorsk, Neryungri, Svobodnyy',
  },
  {
    timeOffset: 'UTC+9:00',
    timeZone: 'Pacific/Palau',
    country: 'Palau',
    cities: 'Koror, Koror Town, Kloulklubed, Ulimang, Mengellang',
  },
  {
    timeOffset: 'UTC+9:30',
    timeZone: 'Australia/Adelaide',
    country: 'Australia',
    cities: 'Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale, Gawler',
  },
  {
    timeOffset: 'UTC+9:30',
    timeZone: 'Australia/Broken_Hill',
    country: 'Australia',
    cities: 'Broken Hill',
  },
  {
    timeOffset: 'UTC+9:30',
    timeZone: 'Australia/Darwin',
    country: 'Australia',
    cities: 'Darwin, Alice Springs, Palmerston, Howard Springs',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Antarctica/DumontDUrville',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Asia/Ust-Nera',
    country: 'Russia',
    cities: '',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Asia/Vladivostok',
    country: 'Russia',
    cities:
      'Vladivostok, Khabarovsk, Khabarovsk Vtoroy, Komsomolsk-on-Amur, Ussuriysk',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Brisbane',
    country: 'Australia',
    cities: 'Brisbane, Gold Coast, Logan City, Townsville, Cairns',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Currie',
    country: 'Australia',
    cities: '',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Hobart',
    country: 'Australia',
    cities: 'Hobart, Launceston, Burnie, Devonport, Sandy Bay',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Lindeman',
    country: 'Australia',
    cities: '',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Melbourne',
    country: 'Australia',
    cities: 'Melbourne, Geelong, Bendigo, Ballarat, Melbourne City Centre',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Australia/Sydney',
    country: 'Australia',
    cities: 'Sydney, Canberra, Newcastle, Wollongong, Maitland',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Pacific/Chuuk',
    country: 'Micronesia',
    cities: 'Weno, Colonia',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Pacific/Guam',
    country: 'Guam',
    cities:
      'Dededo Village, Yigo Village, Tamuning, Tamuning-Tumon-Harmon Village, Mangilao Village',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Pacific/Port_Moresby',
    country: 'Papua New Guinea',
    cities: 'Port Moresby, Lae, Mount Hagen, Popondetta, Madang',
  },
  {
    timeOffset: 'UTC+10:00',
    timeZone: 'Pacific/Saipan',
    country: 'Northern Mariana Islands',
    cities: 'Saipan, San Jose Village',
  },
  {
    timeOffset: 'UTC+10:30',
    timeZone: 'Australia/Lord_Howe',
    country: 'Australia',
    cities: '',
  },
  {
    timeOffset: 'UTC+11',
    timeZone: 'Antarctica/Macquarie',
    country: 'Australia',
    cities: '',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Asia/Magadan',
    country: 'Russia',
    cities: 'Magadan, Ust-Nera, Susuman, Ola',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Asia/Sakhalin',
    country: 'Russia',
    cities: 'Yuzhno-Sakhalinsk, Korsakov, Kholmsk, Okha, Nevel’sk',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Asia/Srednekolymsk',
    country: 'Russia',
    cities: '',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Bougainville',
    country: 'Papua New Guinea',
    cities: 'Arawa, Buka',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Efate',
    country: 'Vanuatu',
    cities: 'Port-Vila, Luganville, Isangel, Sola, Lakatoro',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Guadalcanal',
    country: 'Solomon Islands',
    cities: 'Honiara, Malango, Auki, Gizo, Buala',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Kosrae',
    country: 'Micronesia',
    cities: 'Tofol',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Norfolk',
    country: 'Norfolk Island',
    cities: 'Kingston',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Noumea',
    country: 'New Caledonia',
    cities: 'Nouméa, Mont-Dore, Dumbéa, Païta, Wé',
  },
  {
    timeOffset: 'UTC+11:00',
    timeZone: 'Pacific/Pohnpei',
    country: 'Micronesia',
    cities: 'Kolonia, Kolonia Town, Palikir - National Government Center',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Antarctica/McMurdo',
    country: 'Antarctica',
    cities: '',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Asia/Anadyr',
    country: 'Russia',
    cities: 'Anadyr, Bilibino',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Asia/Kamchatka',
    country: 'Russia',
    cities:
      'Petropavlovsk-Kamchatsky, Yelizovo, Vilyuchinsk, Klyuchi, Mil’kovo',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Auckland',
    country: 'New Zealand',
    cities: 'Auckland, Wellington, Christchurch, Manukau City, North Shore',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Fiji',
    country: 'Fiji',
    cities: 'Suva, Lautoka, Nadi, Labasa, Ba',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Funafuti',
    country: 'Tuvalu',
    cities:
      'Funafuti, Savave Village, Tanrake Village, Toga Village, Asau Village',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Kwajalein',
    country: 'Marshall Islands',
    cities: 'Ebaye, Jabat',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Majuro',
    country: 'Marshall Islands',
    cities: 'Majuro, Arno, Jabor, Wotje, Mili',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Nauru',
    country: 'Nauru',
    cities: 'Yaren, Baiti, Anabar, Uaboe, Ijuw',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Tarawa',
    country: 'Kiribati',
    cities: 'Tarawa, Betio Village, Bikenibeu Village',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Wake',
    country: 'United States Minor Outlying Islands',
    cities: '',
  },
  {
    timeOffset: 'UTC+12:00',
    timeZone: 'Pacific/Wallis',
    country: 'Wallis and Futuna',
    cities: 'Mata-Utu, Leava, Alo',
  },
  {
    timeOffset: 'UTC+12:45',
    timeZone: 'Pacific/Chatham',
    country: 'New Zealand',
    cities: 'Waitangi',
  },
  {
    timeOffset: 'UTC+13:00',
    timeZone: 'Pacific/Apia',
    country: 'Samoa',
    cities: 'Apia, Asau, Mulifanua, Afega, Leulumoega',
  },
  {
    timeOffset: 'UTC+13:00',
    timeZone: 'Pacific/Enderbury',
    country: 'Kiribati',
    cities: '',
  },
  {
    timeOffset: 'UTC+13:00',
    timeZone: 'Pacific/Fakaofo',
    country: 'Tokelau',
    cities: 'Atafu Village, Nukunonu, Fale old settlement',
  },
  {
    timeOffset: 'UTC+13:00',
    timeZone: 'Pacific/Tongatapu',
    country: 'Tonga',
    cities: 'Nuku‘alofa, Lapaha, Neiafu, Pangai, ‘Ohonua',
  },
  {
    timeOffset: 'UTC+14:00',
    timeZone: 'Pacific/Kiritimati',
    country: 'Kiribati',
    cities: '',
  },
];
