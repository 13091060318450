import countryList from 'country-list';
import { sortBy } from 'lodash';
import React from 'react';

import { BaseInputFieldProps } from './BaseInputField';
import { SelectField } from './SelectField';

const options = countryList.getData().map(({ code, name }) => ({
  value: code,
  label: name,
}));

const sortedOptions = sortBy(options, (a) => a.label);

export const CountryField = (props: BaseInputFieldProps) => {
  return <SelectField options={sortedOptions} {...props} />;
};
