type StorageType = 'sessionStorage' | 'localStorage';

const storageTypeMap: Record<StorageType, Storage | undefined> = {
  localStorage:
    (typeof window !== 'undefined' && window.localStorage) || undefined,
  sessionStorage:
    (typeof window !== 'undefined' && window.sessionStorage) || undefined,
};

const createGetStorageValue =
  <Value extends string = string>(
    storageType: StorageType,
    storageKey: string,
  ) =>
  () => {
    const storage = storageTypeMap[storageType];
    const stored = storage?.getItem(storageKey);

    if (stored) {
      return stored as Value | undefined;
    }

    return undefined;
  };

const createSetStorageValue =
  <Value extends string = string>(
    storageType: StorageType,
    storageKey: string,
  ) =>
  (value: Value) => {
    const storage = storageTypeMap[storageType];
    storage?.setItem(storageKey, value);
  };

const createRemoveStorageValue =
  (storageType: StorageType, storageKey: string) => () => {
    const storage = storageTypeMap[storageType];
    storage?.removeItem(storageKey);
  };

export const createStorage = <Value extends string = string>(
  storageType: StorageType,
  storageKey: string,
) => ({
  get: createGetStorageValue<Value>(storageType, storageKey),
  set: createSetStorageValue<Value>(storageType, storageKey),
  remove: createRemoveStorageValue(storageType, storageKey),
});
