import { RouteComponentProps } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Select from 'react-select';

import { Button, GlobalSearch, Link, Theme } from '@/components';
import { CloseIcon, HamburgerIcon } from '@/components/icons';
import { Nullable } from '@/types';
import { cleanArray } from '@/utils';
import { Operator, useCurrentOperator, useOperators } from '@/utils/operators';
import {
  LayoutQuery,
  LayoutQuery_siteSettings,
} from './__generated__/LayoutQuery';
import { GlobalOptions } from './GlobalOptions';
import { TimePicker } from './Timepicker';

const LAYOUT_QUERY = graphql`
  query LayoutQuery {
    siteSettings: sanitySiteSettings {
      title
      navMenu {
        items {
          _key
          ...LinkFragment
        }
      }
      theme {
        ...ThemeFragment
      }
    }
  }
`;

const OperatorGlobalSearch = ({
  currentOperator,
}: {
  currentOperator: Nullable<Operator>;
}) => {
  if (!currentOperator) {
    return null;
  }

  return (
    <div className="flex items-center flex-shrink-0 mr-3 flex-auto md:flex-initial">
      <GlobalSearch />
    </div>
  );
};

const NavItems = ({
  currentOperator,
  siteSettings,
}: {
  currentOperator: Nullable<Operator>;
  siteSettings: LayoutQuery_siteSettings;
}) => {
  if (!currentOperator || !siteSettings.navMenu?.items) {
    return null;
  }

  return (
    <>
      {cleanArray(siteSettings.navMenu.items).map((link) => (
        <Button
          as={Link}
          key={link._key}
          link={link}
          activeClassName="bg-gray-900"
          className="block md:inline-block px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 dark:text-gray-300 dark:hover:text-white"
        />
      ))}
    </>
  );
};

export const Layout: React.FC<RouteComponentProps> = ({ children }) => {
  const { siteSettings } = useStaticQuery<LayoutQuery>(LAYOUT_QUERY);
  const [open, setOpen] = useState(false);

  const operators = useOperators();
  const currentOperator = useCurrentOperator();

  if (!siteSettings) {
    return null;
  }

  return (
    <Theme
      theme={siteSettings.theme}
      style={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <nav className="flex items-center justify-between flex-wrap p-3 bg-primary">
        <div className="flex items-center flex-shrink-0 mr-1">
          <span className="font-semibold text-xl tracking-tight">
            <Select
              className="w-28 mr-2"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'transparent',
                  border: 'none',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'white',
                }),
                indicatorSeparator: () => ({}),
              }}
              value={
                currentOperator
                  ? {
                      value: currentOperator,
                      label: currentOperator.name,
                    }
                  : undefined
              }
              options={operators.map((org) => ({
                value: org,
                label: org.name,
              }))}
              isSearchable={false}
              onChange={(value) => navigate(`/${value?.value?.path}`)}
            />
          </span>
        </div>
        <OperatorGlobalSearch currentOperator={currentOperator} />
        <div className="block md:hidden">
          <Button
            onClick={() => setOpen((a) => !a)}
            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            {open ? <CloseIcon /> : <HamburgerIcon />}
          </Button>
        </div>
        <div
          className={`w-full flex-grow md:flex md:items-center md:w-auto ${
            open ? 'block' : 'hidden'
          }`}
        >
          <div className="text-sm md:flex md:flex-grow md:justify-between">
            <div className="md:inline-flex">
              <NavItems
                currentOperator={currentOperator}
                siteSettings={siteSettings}
              />
            </div>
            <div className="flex items-stretch">
              <TimePicker buttonClassName="block md:inline-block px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 bg-gray-900" />
              <GlobalOptions buttonClassName="p-2 dark:text-gray-200 dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:active:bg-gray-800" />
            </div>
          </div>
        </div>
      </nav>
      {children}
    </Theme>
  );
};
