import { Nullable } from '@/types';
import { isObject } from './is-object';
import { isTruthy } from './is-truthy';

function notEmpty<TValue>(
  value: Nullable<TValue>,
): value is NonNullable<TValue> {
  return value !== null && value !== undefined;
}

export const cleanArray = <T = unknown>(list: Nullable<T[]>) => {
  return list ? list.filter(notEmpty) : [];
};

export const cleanFalsyArray = <T = unknown>(list: T[]) => {
  return list ? list.filter(isTruthy) : [];
};

export const cleanNonObjectsArray = <T = unknown>(list: T[]) => {
  return list ? list.filter(isObject) : [];
};
