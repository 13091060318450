import { graphql, useStaticQuery } from 'gatsby';

import { assert } from '@/utils/error';
import { UseFeedbackMessagesBitStaticQuery } from './__generated__/UseFeedbackMessagesBitStaticQuery';

const query = graphql`
  query UseFeedbackMessagesBitStaticQuery {
    sanityFeedbackMessageBit {
      success {
        ...LocaleString
      }
    }
  }
`;

export const useFeedbackMessages = () => {
  const staticData = useStaticQuery<UseFeedbackMessagesBitStaticQuery>(query);

  assert(staticData.sanityFeedbackMessageBit, 'missing feedback messages');

  return staticData.sanityFeedbackMessageBit;
};
