import React from 'react';

import { InlineIconButton } from '@/components';
import { TrashIcon } from '@/components/icons';

type Props = {
  options: ListItemProps[];
  removeItem: (itemId: string) => void;
  removeAll: () => void;
};

type ListItemProps = {
  name: string;
  itemId: string;
};

const SelectedList = (props: Props) => {
  const { options, removeItem, removeAll } = props;

  if (options.length === 0) {
    return null;
  }

  return (
    <div className="w-full mt-1 border">
      <div className="max-h-56 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch md:max-h-md">
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className="flex items-center justify-between p-1.5 border dark:border-gray-600 w-full dark:text-gray-200"
            >
              {option.name}
              <InlineIconButton
                type="button"
                onClick={() => removeItem(option.itemId)}
              >
                <TrashIcon title="Remove" />
              </InlineIconButton>
            </div>
          );
        })}
      </div>

      <div className="flex items-center border-t p-1 pl-0 text-left text-sm text-gray-500">
        <InlineIconButton type="button" className="ml-0" onClick={removeAll}>
          Delete All
        </InlineIconButton>
        <div className="ml-auto">
          {options.length} {options.length === 1 ? 'player' : 'players'}
        </div>
      </div>
    </div>
  );
};

export default SelectedList;
