import React, { FC } from 'react';

let Devtools: FC = ({ children }) => <>{children}</>;

try {
  Devtools = require('@ui-devtools/tailwind').Devtools;
} catch (e) {
  console.log(e);
}

export const DevtoolsWrapper: FC = ({ children }) => {
  return (
    <Devtools>
      <div className="p-6">{children}</div>
    </Devtools>
  );
};
