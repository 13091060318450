import _ from 'lodash';

import { Primitive } from '@/types';

export type DeepMapVariables<T> = T extends Date
  ? string
  : T extends Primitive
  ? T
  : T extends T[]
  ? DeepMapVariables<T>[]
  : T extends {}
  ? { [K in keyof T]: DeepMapVariables<T[K]> }
  : T;

/**
 * This will keep all primitive values but convert Dates to strings.
 *
 * Date is the only type so far that we need to convert to a string in order to match the GraphQL schema.
 * If we need more in the future, this is the place to add them.
 */
export const mapVariables = <T extends unknown>(
  obj: T,
): DeepMapVariables<T> => {
  if (obj instanceof Date) {
    return obj.toJSON() as DeepMapVariables<T>;
  }

  if (_.isArray(obj)) {
    return obj.map((a) => mapVariables(a)) as DeepMapVariables<T>;
  }

  if (_.isObject(obj)) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      // @ts-expect-error
      acc[key] = mapVariables(value);
      return acc;
    }, {}) as DeepMapVariables<T>;
  }

  return obj as DeepMapVariables<T>;
};
