import React, { Dispatch, FC, Reducer, useEffect, useReducer } from 'react';

import { DataSourceVersionEnum } from '@/globalTypes';
import { createStorage } from '@/utils/storage';
import { ContextBuilder } from './ContextBuilder';

type DataSourceContextState = {
  dataSource: DataSourceVersionEnum | null;
};

type RemoveDataSourceAction = {
  type: 'REMOVE_DATA_SOURCE';
};

type SetDataSourceAction = {
  type: 'SET_DATA_SOURCE';
  dataSource: DataSourceVersionEnum;
};

type DataSourceAction = SetDataSourceAction | RemoveDataSourceAction;

const DataSourceContext = new ContextBuilder<DataSourceContextState>(
  'DataSourceContext',
);
const DispatchDataSourceContext = new ContextBuilder<
  Dispatch<DataSourceAction>
>('DispatchDataSourceContext');

export const useDataSource = DataSourceContext.use;
export const useDispatchDataSource = DispatchDataSourceContext.use;

type DataSourceReducer = Reducer<DataSourceContextState, DataSourceAction>;

const reducer: DataSourceReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_SOURCE': {
      return {
        ...state,
        dataSource: action.dataSource,
      };
    }
    case 'REMOVE_DATA_SOURCE':
      return {
        ...state,
        dataSource: null,
      };
    default:
      return state;
  }
};

const storage = createStorage<DataSourceVersionEnum>(
  'sessionStorage',
  'dataSource',
);

export const DataSourceProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<DataSourceReducer, { dataSource: null }>(
    reducer,
    { dataSource: null },
    () => ({
      dataSource: storage.get() ?? null,
    }),
  );

  useEffect(() => {
    if (state.dataSource) {
      storage.set(state.dataSource);
      return;
    }

    storage.remove();
  }, [state.dataSource]);

  return (
    <DispatchDataSourceContext.Context.Provider value={dispatch}>
      <DataSourceContext.Context.Provider value={state}>
        {children}
      </DataSourceContext.Context.Provider>
    </DispatchDataSourceContext.Context.Provider>
  );
};
