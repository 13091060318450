import { compareAsc } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import ms from 'ms.macro';
import { ContextBuilder } from '../ContextBuilder';
import { predefinedTimeFrames } from './predefinedTimeFrames';
import {
  CustomTimeFrame,
  PredefinedNames,
  PredefinedTimeFrame,
} from './TimeFrame';

export type TimeValue = {
  granularity: GranularityEnum;
  timeZone: string;
  from: Date;
  to: Date;
  nullableFrom?: Date | null;
  nullableTo?: Date | null;
};

type TimeFrameTypes = CustomTimeFrame | PredefinedTimeFrame;

enum AutoEnum {
  Auto = 'Auto',
}

export const TimeGranularity = {
  ...AutoEnum,
  ...GranularityEnum,
};

type TimeGranularityType = AutoEnum | GranularityEnum;

type SetCustomTimeInput = {
  from: Date;
  to: Date;
  timeGranularity?: TimeGranularityType;
  timeZone?: string;
};

type SetPredefinedTimeInput = {
  name: PredefinedNames;
  timeGranularity?: TimeGranularityType;
  timeZone?: string;
};

type TimeContextValue = {
  timeFrame: TimeFrameTypes;
  timeGranularity: TimeGranularityType;
  time: TimeValue;
  graphQLVariables: Omit<TimeValue, 'from' | 'to'> & {
    from: string;
    to: string;
  };
  setCustomTime: (input: SetCustomTimeInput) => void;
  setPredefinedTime: (input: SetPredefinedTimeInput) => void;
};

const TimeContext = new ContextBuilder<TimeContextValue>('TimeContext');

const STORAGE_KEY = 'time_context';

const setStoredValue = (state: StateType) => {
  if (typeof window !== 'undefined' && 'sessionStorage' in window) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }
};

const safeParseJson = (str: Nullable<string>) => {
  if (!str) {
    return undefined;
  }

  try {
    return JSON.parse(str);
  } catch {
    return undefined;
  }
};

const getStoredValue = (): StateType | undefined => {
  if (typeof window === 'undefined' || !('sessionStorage' in window)) {
    return undefined;
  }

  const timeString = sessionStorage.getItem(STORAGE_KEY);

  if (!timeString) {
    return undefined;
  }

  const jsonValue = safeParseJson(timeString);

  if (jsonValue?.timeFrame?.type === CustomTimeFrame.type) {
    const timeFrame = CustomTimeFrame.deserialize(jsonValue.timeFrame);

    if (timeFrame) {
      return { ...jsonValue, timeFrame };
    }
  }

  if (jsonValue?.timeFrame?.type === PredefinedTimeFrame.type) {
    const timeFrame = PredefinedTimeFrame.deserialize(jsonValue?.timeFrame);
    if (timeFrame) {
      return {
        ...jsonValue,
        timeFrame,
      };
    }
  }

  return undefined;
};

export const useTime = TimeContext.use;

type StateType = {
  timeGranularity: TimeGranularityType;
  timeZone: string;
  timeFrame: TimeFrameTypes;
};

const calculateGranularity = (timeFrame: TimeFrameTypes): GranularityEnum => {
  const { from, to } = timeFrame.getInterval();

  const diffInMs = Math.abs(to.getTime() - from.getTime());

  if (diffInMs < ms('10 minutes')) {
    return GranularityEnum.Second;
  }
  if (diffInMs < ms('10 hours')) {
    return GranularityEnum.Minute;
  }
  if (diffInMs < ms('10 days')) {
    return GranularityEnum.Hour;
  }
  if (diffInMs < ms('10 weeks')) {
    return GranularityEnum.Day;
  }
  if (diffInMs < ms('1 years')) {
    return GranularityEnum.Week;
  }
  if (diffInMs < ms('10 years')) {
    return GranularityEnum.Month;
  }
  if (diffInMs < ms('20 years')) {
    return GranularityEnum.Quarter;
  }
  return GranularityEnum.Year;
};

export const TimeProvider: FC<{
  initialState?: StateType;
  root?: boolean;
}> = ({ children, initialState, root = false }) => {
  const [state, setState] = useState(
    getInitialStateOptions(root, initialState),
  );

  useEffect(() => {
    if (root) {
      setStoredValue(state);
    }
  }, [state, root]);

  const ctx = useMemo(() => {
    const { timeFrame, timeZone, timeGranularity } = state;

    const granularity =
      timeGranularity !== TimeGranularity.Auto
        ? timeGranularity
        : calculateGranularity(timeFrame);

    const interval = timeFrame.getInterval();

    return {
      time: {
        timeZone,
        granularity,
        ...interval,
      },
      graphQLVariables: {
        timeZone,
        granularity,
        from: interval.from.toJSON(),
        to: interval.to.toJSON(),
      },
      timeGranularity,
      timeFrame,
      setCustomTime: ({
        from: fromDate,
        to: toDate,
        ...rest
      }: SetCustomTimeInput) => {
        const [from, to] = [fromDate, toDate].sort(compareAsc);

        const timeFrame = new CustomTimeFrame({ from, to });

        setState((value) => ({ ...value, ...rest, timeFrame }));
      },
      setPredefinedTime: ({ name, ...rest }: SetPredefinedTimeInput) => {
        const timeFrame = predefinedTimeFrames[name];

        setState((value) => ({ ...value, ...rest, timeFrame }));
      },
    };
  }, [state, setState]);

  return (
    <TimeContext.Context.Provider value={ctx}>
      {children}
    </TimeContext.Context.Provider>
  );
};

export const initialStateOptions = {
  timeFrame: predefinedTimeFrames['past-30-days'],
  timeGranularity: TimeGranularity.Auto,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const getInitialStateOptions = (
  root: boolean,
  initialState: StateType = initialStateOptions,
) => {
  if (root) {
    return getStoredValue() || initialState;
  }
  return initialState;
};
