import React, { useCallback, useEffect, useState } from 'react';

import browserOnly from '@/utils/browserOnly';
import { Alert } from '../Alert';
import { getDriver } from './drivers';

const useFileType = (filePath: string) => {
  const [error, setError] = useState<string>();
  const [fileType, setFileType] = useState<string>();

  useEffect(() => {
    let discarded = false;

    fetch(filePath).then((resp) => {
      if (!resp.ok) {
        console.error(resp);
        return setError(resp.statusText);
      }

      const contentType = resp.headers.get('Content-Type');

      if (!contentType || discarded) {
        return;
      }

      const parts = contentType.split('/');
      const fileType = parts[1] === 'msword' ? 'docx' : parts[1];

      setFileType(fileType);
    });

    return () => {
      discarded = true;
    };
  }, [filePath]);

  return { fileType, fileTypeError: error };
};

type Props = {
  filePath: string;
};

const FileViewer = ({ filePath }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { fileType, fileTypeError } = useFileType(filePath);
  const Driver = (fileType && getDriver(fileType)) || undefined;

  const onLoaded = useCallback(() => setIsLoading(false), []);

  if (fileTypeError) {
    return (
      <div className="w-full">
        <Alert variant="box">{fileTypeError}</Alert>
      </div>
    );
  }

  if (fileType && Driver) {
    return (
      <div className="flex justify-center">
        <Driver
          filePath={filePath}
          fileType={fileType}
          isLoading={isLoading}
          onLoaded={onLoaded}
        />
      </div>
    );
  }

  return null;
};

export default browserOnly(FileViewer);
