import React, { FC, ReactNode } from 'react';

import { Copy, InlineSpinner } from '@/components';
import { Nullable } from '@/types';
import { ErrorIcon } from './icons/ErrorIcon';

export const renderOptionalValue = <Value extends string | number | ReactNode>(
  value: Nullable<Value>,
) => value || '-';

export const Value: FC<{
  title: string | ReactNode;
  value?: Nullable<string | number>;
  fetching?: boolean;
  error?: boolean;
  suffix?: ReactNode;
  className?: string;
}> = ({ title, value, fetching, error, children, suffix, className }) => {
  const nonValueContent = (
    <>
      {renderOptionalValue(children)}
      {suffix}
    </>
  );

  return (
    <div className="text-black dark:text-gray-200">
      <label className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
        {title}
      </label>
      <div className={className}>
        {fetching ? (
          <InlineSpinner />
        ) : error ? (
          <ErrorIcon className="text-gray-600" />
        ) : value ? (
          <div className="flex items-center">
            <Copy value={value}>{children || value}</Copy>
            {suffix}
          </div>
        ) : children ? (
          nonValueContent
        ) : (
          <span className="text-gray-500">{nonValueContent}</span>
        )}
      </div>
    </div>
  );
};
