import React, { FC } from 'react';

export const LoadingTable: FC<{ rows: number; columns: number }> = ({
  rows,
  columns,
}) => {
  return (
    <>
      {[...Array(rows)].map((_row, index) => (
        <tr key={index}>
          {[...Array(columns)].map((_col, colIndex) => (
            <td key={colIndex} className="w-full p-3">
              <div className="skeleton-box">&nbsp;</div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
