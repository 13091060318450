import React, { FC, ReactNode } from 'react';

export type DetailListItem = {
  label: ReactNode;
  value: ReactNode;
};

export const DetailList: FC<{
  items: DetailListItem[];
}> = ({ items }) => (
  <dl>
    {items.map((item, index) => (
      <div
        key={index}
        className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
      >
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">
          {item.label}
        </dt>
        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2">
          {item.value}
        </dd>
      </div>
    ))}
  </dl>
);
