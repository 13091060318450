import classNames from 'classnames';
import React, { FC } from 'react';

type DisplayVariant = 'box' | 'inline';

export const Alert: FC<{ variant?: DisplayVariant }> = ({
  children,
  variant = 'inline',
}) => (
  <div
    className={classNames(
      'text-red-600 dark:text-red-500 col-span-full text-sm',
      {
        'bg-red-100 p-2 rounded': variant === 'box',
      },
    )}
  >
    {children}
  </div>
);
