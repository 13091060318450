import React, { useEffect, useState } from 'react';

import { Button, ButtonProps } from './Button';

const useConfirmState = () => {
  const [confirming, setConfirming] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (confirming) {
      timeoutId = setTimeout(() => {
        setConfirming(false);
      }, 5000);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [confirming]);

  return {
    confirming,
    setConfirming,
  };
};

type Props = Omit<ButtonProps<'button'>, 'as'>;

export const ConfirmButton = ({ children, onClick, ...rest }: Props) => {
  const { confirming, setConfirming } = useConfirmState();

  return (
    <Button<'button'>
      {...rest}
      onClick={(event) => {
        if (onClick) {
          if (confirming) {
            setConfirming(false);
            return onClick(event);
          }
          setConfirming(true);
        }
      }}
    >
      {confirming ? 'Are you sure?' : children}
    </Button>
  );
};
