import { useLocalStorage } from 'react-use';

import { DEFAULT_LANGUAGE, LanguageCodes } from '@/utils/locale';

type SetAction = React.Dispatch<React.SetStateAction<LanguageCodes>>;

export const useLanguage = (): [LanguageCodes, SetAction] => {
  const [locale, setLocale] = useLocalStorage<LanguageCodes>(
    'locale',
    DEFAULT_LANGUAGE,
  );

  return [locale as LanguageCodes, setLocale as SetAction];
};
