import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { PageContextFragment } from './__generated__/PageContextFragment';
import { ContextBuilder } from './ContextBuilder';

export const PageContextFragmentDeclaration = graphql`
  fragment PageContextFragment on SanityPage {
    title
  }
`;

const PageContext = new ContextBuilder<PageContextFragment>('PageContext');

export const usePage = PageContext.use;

export const PageProvider: FC<{ page: PageContextFragment }> = ({
  children,
  page,
}) => {
  return (
    <PageContext.Context.Provider value={page}>
      {children}
    </PageContext.Context.Provider>
  );
};
