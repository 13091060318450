import {
  DecodedValueMap,
  QueryParamConfigMap,
  SetQuery,
} from 'use-query-params';

export function makeOrderBy<
  O,
  QPCMap extends QueryParamConfigMap & { orderBy?: O; desc: boolean }
>(query: DecodedValueMap<QPCMap>, setQuery: SetQuery<QPCMap>) {
  return {
    setOrderBy: (order: O) => {
      if (order === query.orderBy) {
        setQuery({ ...query, desc: !query.desc });
      } else {
        setQuery({ ...query, orderBy: order, desc: false });
      }
    },
    orderBy: query.orderBy,
    desc: query.desc,
  };
}

export function makePagination<
  O,
  QPCMap extends QueryParamConfigMap & { orderBy?: O; desc: boolean },
  PageInfo extends {
    hasNextPage: boolean;
    endCursor: string | null;
    hasPreviousPage: boolean;
    startCursor: string | null;
  }
>(
  query: DecodedValueMap<QPCMap>,
  setQuery: SetQuery<QPCMap>,
  pageInfo: PageInfo | undefined,
) {
  return {
    nextPage: pageInfo?.hasNextPage
      ? () =>
          setQuery({
            ...query,
            after: pageInfo.endCursor || undefined,
            before: undefined,
          })
      : null,
    previousPage: pageInfo?.hasPreviousPage
      ? () =>
          setQuery({
            ...query,
            before: pageInfo.startCursor || undefined,
            after: undefined,
          })
      : null,
  };
}
