import format from 'date-fns/format';

import { Nullable } from '@/types';

const isValidDate = (date: ConstructorParameters<typeof Date>[0]) =>
  !isNaN(new Date(date).getTime());

export const dateFormats = {
  date: 'yyyy-MM-dd',
  dateTime: 'yyyy-MM-dd HH:mm',
};

export default function formatDate(
  date?: Nullable<ConstructorParameters<typeof Date>[0]>,
  hideHour?: boolean,
) {
  if (!date || !isValidDate(date)) {
    return '-';
  }

  return format(
    new Date(date),
    hideHour ? dateFormats.date : dateFormats.dateTime,
  );
}
