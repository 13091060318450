import loadable, { LoadableComponent } from '@loadable/component';
import { graphql } from 'gatsby';
import React, { FC, Fragment, ReactNode } from 'react';

import { ErrorBoundary } from '@/bits';
import { Nullable } from '@/types';
import { cleanArray } from '@/utils';
import { BlockRendererFragment } from './__generated__/BlockRendererFragment';
import { OUTLET_SANITY_TYPE } from './outlet-block/constants';

type BlockTypeMap = Record<
  string,
  LoadableComponent<{ block: any }> | LoadableComponent<{ block?: any }>
>;

const typeNameToComponent: BlockTypeMap = {
  SanityAnalyticsBlock: loadable(() => import('./analytics-block/component')),
  SanityBreadcrumbsBlock: loadable(
    () => import('./breadcrumbs-block/component'),
  ),
  SanityActivityMapBlock: loadable(
    () => import('./activity-map-block/component'),
  ),
  SanityBulkAdjustmentsBlock: loadable(
    () => import('./bulk-adjustments-block/component'),
  ),
  SanityCashbackReportBlock: loadable(
    () => import('./cashback-report-block/component'),
  ),
  SanityClosingBalancesReportBlock: loadable(
    () => import('./closing-balances-report-block/component'),
  ),
  SanityCreateExcludedPlayerFormBlock: loadable(
    () => import('./create-excluded-player-form-block/component'),
  ),
  SanityGenericBreadcrumbsBlock: loadable(
    () => import('./generic-breadcrumbs-block/component'),
  ),
  SanityMarketingFinanceEmbedBlock: loadable(
    () => import('./marketing-finance-embed-block/component'),
  ),
  SanityMarketingListReportBlock: loadable(
    () => import('./marketing-list-report-block/component'),
  ),
  SanityTimeChartBlock: loadable(() => import('./time-chart-block/component')),
  SanityPlayerActivityInfoBlock: loadable(
    () => import('./player-activity-info-block/component'),
  ),
  SanityPlayerAdjustmentsBlock: loadable(
    () => import('./player-adjustments-block/component'),
  ),
  SanityPlayerNotesBlock: loadable(
    () => import('./player-notes-block/component'),
  ),
  SanityPlayerBreadcrumbsBlock: loadable(
    () => import('./player-breadcrumbs-block/component'),
  ),
  SanityPlayerDepositLimitsBlock: loadable(
    () => import('./player-deposit-limits-block/component'),
  ),
  SanityPlayerDocumentsBlock: loadable(
    () => import('./player-documents-block/component'),
  ),
  SanityPlayerGameRoundsBlock: loadable(
    () => import('./player-game-rounds-block/component'),
  ),
  SanityPlayerGamesBlock: loadable(
    () => import('./player-games-block/component'),
  ),
  SanityPlayerInfoBlock: loadable(
    () => import('./player-info-block/component'),
  ),
  SanityPlayerInfoHistoryBlock: loadable(
    () => import('./player-info-history-block/component'),
  ),
  SanityKpiBlock: loadable(() => import('./kpi-block/component')),
  SanityKycValidationsBlock: loadable(
    () => import('./kyc-validations-block/component'),
  ),
  SanityLinkedAccountsBlock: loadable(
    () => import('./linked-accounts-block/component'),
  ),
  SanityPlayerKycHistoryBlock: loadable(
    () => import('./player-kyc-history-block/component'),
  ),
  SanityPlayerKycOverviewBlock: loadable(
    () => import('./player-kyc-overview-block/component'),
  ),
  SanityPlayerLimitHistoryBlock: loadable(
    () => import('./player-limits-history-block/component'),
  ),
  SanityPlayerMarketingBlock: loadable(
    () => import('./player-marketing-block/component'),
  ),
  SanityPlayerPaymentsBlock: loadable(
    () => import('./player-payments-block/component'),
  ),
  SanityPlayerPhoneLoginsBlock: loadable(
    () => import('./player-phone-logins-block/component'),
  ),
  SanityPlayerQuickOverviewBlock: loadable(
    () => import('./player-quick-overview-block/component'),
  ),
  SanityTransactionInspectionBlock: loadable(
    () => import('./transaction-inspection-block/component'),
  ),
  SanityPlayerSearchResultBlock: loadable(
    () => import('./player-search-result-block/component'),
  ),
  SanityPlayerSelfExclusionBlock: loadable(
    () => import('./player-self-exclusion-block/component'),
  ),
  SanityPlayerSelfExclusionHistoryBlock: loadable(
    () => import('./player-self-exclusion-history-block/component'),
  ),
  SanityPlayerSelfExclusionReportBlock: loadable(
    () => import('./player-self-exclusion-report-block/component'),
  ),
  SanityPlayerSessionLimitsBlock: loadable(
    () => import('./player-session-limits-block/component'),
  ),
  SanityPlayerSessionsBlock: loadable(
    () => import('./player-sessions-block/component'),
  ),
  SanityPlayerSowBlock: loadable(() => import('./player-sow-block/component')),
  SanityPlayerSowHistoryBlock: loadable(
    () => import('./player-sow-history-block/component'),
  ),
  SanityPlayerTransactionsBlock: loadable(
    () => import('./player-transactions-block/component'),
  ),
  SanityPlayerWalletBlock: loadable(
    () => import('./player-wallet-block/component'),
  ),
  SanityPlayerRiskAssessmentOverviewBlock: loadable(
    () => import('./player-risk-assessment-overview-block/component'),
  ),
  SanityPlayerRiskAssessmentHistoryBlock: loadable(
    () => import('./player-risk-assessment-history-block/component'),
  ),
  SanityGameInfoBlock: loadable(() => import('./game-info-block/component')),
  SanityPlayerGameRoundInspectionBlock: loadable(
    () => import('./player-game-round-inspection-block/component'),
  ),
  SanityPlayersLinkGeneratorBlock: loadable(
    () => import('./players-link-generator-block/component'),
  ),
  SanityRewardsHistoryBlock: loadable(
    () => import('./reward-history-block/component'),
  ),
  SanityRewardDetailBlock: loadable(
    () => import('./reward-detail-block/component'),
  ),
  SanityPlayerRewardsBlock: loadable(
    () => import('./player-rewards-block/component'),
  ),
  SanitySourceOfWealthBlock: loadable(
    () => import('./source-of-wealth-block/component'),
  ),
  SanityRiskAssessmentBlock: loadable(
    () => import('./risk-assessment-block/component'),
  ),
  SanityTransactionsBlock: loadable(
    () => import('./transactions-block/component'),
  ),
};

export const ContentFragmentDeclarations = graphql`
  fragment BlockRendererFragment on SanityBlockContainer {
    blocksArray {
      __typename
      ...SanityBreadcrumbsBlockFragment
      ...SanityGenericBreadcrumbsBlockFragment
      ...SanityKpiBlockFragment
      ...SanityKycValidationsBlockFragment
      ...SanityPlayerActivityInfoBlockFragment
      ...SanityPlayerAdjustmentsBlockFragment
      ...SanityPlayerBreadcrumbsBlockFragment
      ...SanityPlayerDepositLimitsBlockFragment
      ...SanityPlayerGameRoundInspectionBlockFragment
      ...SanityPlayerGameRoundsBlockFragment
      ...SanityPlayerGamesBlockFragment
      ...SanityPlayerInfoBlockFragment
      ...SanityPlayerInfoHistoryBlockFragment
      ...SanityPlayerKycHistoryBlockFragment
      ...SanityPlayerKycOverviewBlockFragment
      ...SanityPlayerLimitsHistoryBlockFragment
      ...SanityPlayerPaymentsBlockFragment
      ...SanityPlayerPhoneLoginsBlockFragment
      ...SanityPlayerQuickOverviewBlockFragment
      ...SanityPlayerRiskAssessmentOverviewBlockFragment
      ...SanityPlayerRiskAssessmentHistoryBlockFragment
      ...SanityPlayerSearchResultBlockFragment
      ...SanityPlayerSelfExclusionHistoryBlockFragment
      ...SanityPlayerSessionsBlockFragment
      ...SanityPlayerSowHistoryBlockFragment
      ...SanityPlayerTransactionsBlockFragment
      ...SanityPlayerWalletBlockFragment
      ...SanityPlayerRewardsBlockFragment
      ...SanityRewardsHistoryBlockFragment
      ...SanityRewardDetailBlockFragment
      ...SanityTimeChartBlockFragment
      ...SanityTransactionInspectionBlockFragment
      ...SanityTransactionsBlockFragment
    }
  }
`;

export const BlockRenderer: FC<{
  blockContainer: Nullable<BlockRendererFragment>;
  outletContent?: ReactNode;
}> = ({ blockContainer, outletContent }) => {
  return (
    <>
      {cleanArray(blockContainer?.blocksArray).reduce<ReactNode[]>(
        (acc, block, i) => {
          if (block.__typename === OUTLET_SANITY_TYPE) {
            if (outletContent) {
              acc.push(
                <Fragment key={`${i}_outlet`}>{outletContent}</Fragment>,
              );
            }
          } else {
            const Component = typeNameToComponent[block.__typename];
            if (Component) {
              acc.push(
                <ErrorBoundary key={`${i}_block`}>
                  <Component block={block} />
                </ErrorBoundary>,
              );
            } else {
              console.error(`No block component for ${block.__typename}`);
            }
          }
          return acc;
        },
        [],
      )}
    </>
  );
};
