// @ts-expect-error
import { insertParams } from '@reach/router/lib/utils';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { useCallback } from 'react';

import { useGetOperatorPath } from '@/utils/operators';
import {
  SanityLinksBitStaticQuery,
  SanityLinksBitStaticQuery_sanityLinksBit,
} from './__generated__/SanityLinksBitStaticQuery';

const BIT_STATIC_QUERY = graphql`
  query SanityLinksBitStaticQuery {
    sanityLinksBit {
      gamePage {
        path {
          current
        }
      }
      playerGamePage {
        path {
          current
        }
      }
      playerGameRoundPage {
        path {
          current
        }
      }
      playerOverviewPage {
        path {
          current
        }
      }
      playerTransactionPage {
        path {
          current
        }
      }
      playerRewardsPage {
        path {
          current
        }
      }
      rewardDetailPage {
        path {
          current
        }
      }
      playerRiskAssessmentPage {
        path {
          current
        }
      }
      transactionPage {
        path {
          current
        }
      }
    }
  }
`;

const useBuildLink = (
  linkKey: keyof SanityLinksBitStaticQuery_sanityLinksBit,
) => {
  const getOperatorPath = useGetOperatorPath();
  const bit = useStaticQuery<SanityLinksBitStaticQuery>(BIT_STATIC_QUERY)
    .sanityLinksBit;

  const linkPath = bit?.[linkKey]?.path?.current;

  const getLink = useCallback(
    (params = {}) => {
      if (!linkPath) {
        return null;
      }
      return getOperatorPath(insertParams(linkPath, params));
    },
    [getOperatorPath, linkPath],
  );

  return getLink;
};

export const useGetGamePageLink = () => {
  const buildLink = useBuildLink('gamePage');

  return useCallback((gameId: string) => buildLink({ gameId }), [buildLink]);
};

export const useGetPlayerGamePageLink = () => {
  const buildLink = useBuildLink('playerGamePage');

  return useCallback((playerId: string) => buildLink({ playerId }), [
    buildLink,
  ]);
};

export const useGetPlayerGameRoundPageLink = () => {
  const buildLink = useBuildLink('playerGameRoundPage');

  return useCallback((playerId: string) => buildLink({ playerId }), [
    buildLink,
  ]);
};

export const useGetPlayerOverviewPageLink = () => {
  const buildLink = useBuildLink('playerOverviewPage');

  return useCallback((playerId: string) => buildLink({ playerId }), [
    buildLink,
  ]);
};

export const useGetPlayerRiskAssessmentOverviewPageLink = () => {
  const buildLink = useBuildLink('playerRiskAssessmentPage');

  return useCallback(
    (playerGlobalId: string) => buildLink({ playerGlobalId }),
    [buildLink],
  );
};

export const useGetPlayerTransactionPageLink = () => {
  const buildLink = useBuildLink('playerTransactionPage');

  return useCallback((playerId: string) => buildLink({ playerId }), [
    buildLink,
  ]);
};

export const useGetPlayerRewardsPageLink = () => {
  const buildLink = useBuildLink('playerRewardsPage');

  return useCallback((playerId: string) => buildLink({ playerId }), [
    buildLink,
  ]);
};

export const useGetRewardDetailPageLink = () => {
  const buildLink = useBuildLink('rewardDetailPage');

  return useCallback((rewardId: string) => buildLink({ rewardId }), [
    buildLink,
  ]);
};

export const useGetTransactionPageLink = () => {
  const buildLink = useBuildLink('transactionPage');

  return useCallback(
    (rawTransactionId: string) => {
      return `${buildLink()}?${queryString.stringify({ rawTransactionId })}`;
    },
    [buildLink],
  );
};
