import { predefinedTimeFrames } from './predefinedTimeFrames';

export type PredefinedNames =
  | 'today'
  | 'yesterday'
  | 'past-7-days'
  | 'past-30-days'
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month'
  | 'all-time';

type TimeInterval = {
  from: Date;
  to: Date;
  nullableFrom?: Date | null;
  nullableTo?: Date | null;
};

type BaseProps = {
  getInterval: () => TimeInterval;
  isRealTime: boolean;
  serialize: () => {};
};

export class BaseTimeFrame {
  getInterval: BaseProps['getInterval'];
  isRealTime: BaseProps['isRealTime'];
  serialize: BaseProps['serialize'];
  static type: string;

  constructor({ getInterval, isRealTime, serialize }: BaseProps) {
    this.getInterval = getInterval;
    this.isRealTime = isRealTime;
    this.serialize = serialize;
  }

  toJSON() {
    const result = this.serialize();
    return result;
  }
}

export class PredefinedTimeFrame extends BaseTimeFrame {
  name: PredefinedNames;
  static type = 'PredefinedTimeFrame';

  constructor(
    name: PredefinedNames,
    isRealTime: boolean,
    getInterval: BaseProps['getInterval'],
  ) {
    super({
      getInterval,
      isRealTime,
      serialize: () => ({ type: PredefinedTimeFrame.type, name }),
    });
    this.name = name;
  }

  static deserialize = ({ name }: { name: string }) => {
    return Object.values(predefinedTimeFrames).find((a) => a.name === name);
  };
}

export class CustomTimeFrame extends BaseTimeFrame {
  static type = 'CustomTimeFrame';
  name = 'custom';

  constructor(interval: TimeInterval) {
    super({
      getInterval: () => interval,
      isRealTime: interval.to.getTime() > new Date().getTime(),
      serialize: () => ({ type: CustomTimeFrame.type, interval }),
    });
  }

  static deserialize = ({
    interval,
  }: {
    type: string;
    interval: { from: string; to: string };
  }) => {
    return new CustomTimeFrame({
      from: new Date(interval.from),
      to: new Date(interval.to),
    });
  };
}
