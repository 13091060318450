import _ from 'lodash';
import { useMemo } from 'react';
import { gql } from 'urql';

import { useNewNodesOf } from '@/contexts/NewNodesContext';
import { PlayerNoteSearchOrder } from '@/globalTypes';
import { usePagination } from '@/hooks';
import { Nullable } from '@/types';
import { cleanArray } from '@/utils';
import {
  PlayerNotesBlockQuery,
  PlayerNotesBlockQueryVariables,
  PlayerNotesBlockQuery_player_notesV2_edges_node,
} from './__generated__/PlayerNotesBlockQuery';

export const playerNotes_note = gql`
  fragment PlayerNotes_note on Note {
    id
    playerId
    createdAt
    priority
    content
    addedBy {
      __typename
      ... on AgentInitiator {
        agent {
          email
          firstName
          lastName
        }
      }
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const QUERY = gql`
  query PlayerNotesBlockQuery(
    $playerId: ID!
    $orderBy: [PlayerNoteSearchOrder!]
    $after: String
    $before: String
  ) {
    player(playerId: $playerId) {
      id
      notesV2(orderBy: $orderBy, before: $before, after: $after, first: 10) {
        edges {
          node {
            ...PlayerNotes_note
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
  ${playerNotes_note}
`;

export const usePlayerNotes = ({
  playerId,
  orderBy,
}: {
  playerId: string;
  orderBy: PlayerNoteSearchOrder[];
}) => {
  const newNotes = useNewNodesOf('Note');

  const data = usePagination<
    PlayerNotesBlockQuery,
    PlayerNotesBlockQueryVariables,
    Nullable<PlayerNotesBlockQuery_player_notesV2_edges_node>
  >(QUERY, (a) => a?.player.notesV2, {
    variables: {
      playerId,
      orderBy,
    },
  });

  const newPlayerNotes = useMemo(() => {
    return newNotes.filter((a) => a.playerId === playerId);
  }, [newNotes, playerId]);

  const notes = useMemo(
    () =>
      _.uniqBy(
        [...newPlayerNotes, ...cleanArray(data.items)],
        (entry) => entry.id,
      ),
    [data.items, newPlayerNotes],
  );

  return {
    loading: data.loading,
    nextPage: data.nextPage,
    firstPage: data.firstPage,
    previousPage: data.previousPage,
    notes,
  };
};
