import gql from 'graphql-tag';
import React from 'react';
import { useCallback } from 'react';
import { FC } from 'react';
import { OptionProps, OptionTypeBase } from 'react-select/src/types';
import { Client, useClient } from 'urql';

import { makeBrandThumbnail } from '@/utils/makeBrandThumbnail';
import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import { SearchAndSelectField } from '../form';
import {
  PlayersSearchQuery,
  PlayersSearchQueryVariables,
} from './__generated__/PlayersSearchQuery';
import { usePlayersByIds } from './usePlayersByIds';

const PLAYERS_SEARCH_QUERY = gql`
  query PlayersSearchQuery($query: String!) {
    viewer {
      id
      playersAsYouTypeV2(
        firstName: $query
        lastName: $query
        playerId: $query
      ) {
        edges {
          node {
            uuid
            playerId
            firstName
            lastName
            brand {
              id
              code
            }
          }
        }
      }
    }
  }
`;

type Props = {
  id: string;
  name: string;
  title: string;
};

type PlayerProps = {
  uuid: string;
  firstName: string;
  lastName: string;
};

const OptionComponent: FC<OptionTypeBase> = ({ data, children }) => {
  const fluid = data.value.brand
    ? makeBrandThumbnail(data.value.brand)
    : data.value.thumbnail
    ? makeFluidThumbnail(data.value.thumbnail, data.value.thumbnailUpdatedAt)
    : null;

  return (
    <div className="flex flex-col flex-o w-full pl-2 p-1 cursor-pointer hover:bg-gray-200">
      <div className="flex flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap">
        {fluid ? (
          <picture className="flex-none">
            <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
            <img
              src={fluid.src}
              alt=""
              loading="lazy"
              className="w-6 h-6 rounded-sm mr-2"
            />
          </picture>
        ) : (
          <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
        )}
        {children}
      </div>
      <div className="text-xs text-gray-500">{data.value.uuid}</div>
    </div>
  );
};

const optionObject = ({ value }: OptionProps) => {
  return {
    itemId: value.uuid,
    name: `${value.firstName} ${value.lastName}`,
  };
};

const loadOptions = (client: Client) => (query: string, callback: Function) => {
  if (query.length > 0) {
    const players = client
      .query<PlayersSearchQuery, PlayersSearchQueryVariables>(
        PLAYERS_SEARCH_QUERY,
        {
          query,
        },
      )
      .toPromise();

    Promise.all([players]).then(([playerRes]) => {
      const p =
        playerRes?.data?.viewer.playersAsYouTypeV2?.edges?.map((edge) => ({
          value: edge?.node,
          label: `${edge?.node.firstName} ${edge?.node.lastName}`,
        })) || [];

      callback([...p]);
    });
  } else {
    callback([]);
  }
};

const listItem = (item: PlayerProps) => ({
  itemId: item.uuid,
  name: `${item.firstName} ${item.lastName}`,
});

export const PlayersSearch = ({ id, name, title }: Props) => {
  const { players, fetching, setPlayerIds } = usePlayersByIds();

  const client = useClient();

  const onImportBulk = useCallback(
    (playerIds: string[] | undefined) => {
      if (playerIds) setPlayerIds(playerIds);

      return { results: players, loading: fetching };
    },
    [players, setPlayerIds, fetching],
  );

  return (
    <SearchAndSelectField
      id={id}
      name={name}
      title={title}
      loadOptions={loadOptions(client)}
      component={<OptionComponent />}
      isMultiple={true}
      config={{
        enableBulk: true,
        option: optionObject,
        defaultAttrName: 'uuid',
        onImportBulk,
        listItem,
      }}
    />
  );
};
