import { graphql, useStaticQuery } from 'gatsby';
import React, { ComponentProps, ComponentType, FC } from 'react';
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';

import { Button } from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { TranslatedError } from '@/utils/error';
import { ErrorBoundaryStaticQuery } from './__generated__/ErrorBoundaryStaticQuery';

const query = graphql`
  query ErrorBoundaryStaticQuery {
    sanityErrorBoundaryBit {
      defaultErrorMessage {
        ...LocaleString
      }
      retry {
        ...LocaleString
      }
    }
  }
`;

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslate();
  const bit =
    useStaticQuery<ErrorBoundaryStaticQuery>(query).sanityErrorBoundaryBit;

  const errorMessage =
    error instanceof TranslatedError
      ? error.message
      : t(bit?.defaultErrorMessage);

  return (
    <div
      className="bg-red-200 text-red-900 border border-red-300 p-4 space-y-1"
      role="alert"
    >
      <div>{errorMessage}</div>
      <Button
        type="button"
        onClick={resetErrorBoundary}
        variant="secondary"
        className="flex items-center space-x-1 text-xs"
      >
        <RefreshIcon />
        <span>{t(bit?.retry)}</span>
      </Button>
    </div>
  );
}

export const ErrorBoundary: FC = ({ children }) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={console.error}>
    {children}
  </ReactErrorBoundary>
);

export function withErrorBoundary<C extends ComponentType<any>>(Component: C) {
  const WithErrorBoundary = (props: ComponentProps<C>) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  return WithErrorBoundary;
}
