import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { BaseInputFieldProps } from '../BaseInputField';
import { BaseWrapper } from '../BaseWrapper';
import './react-select.css';

export type SelectOption = {
  value: string;
  label: string;
};

export const SelectField = ({
  title,
  name,
  options,
  isMulti,
  isClearable,
  ...props
}: BaseInputFieldProps & {
  isMulti?: boolean;
  isClearable?: boolean;
  options: SelectOption[];
}) => {
  const { control } = useFormContext();
  return (
    <BaseWrapper title={title} name={name} {...props}>
      <Controller
        name={name}
        control={control}
        rules={{ required: props.required }}
        render={({ field }) => (
          <Select
            value={
              isMulti
                ? field.value
                  ? options.filter(({ value }) => field.value.includes(value))
                  : undefined
                : options.find(({ value }) => value === field.value)
            }
            options={options}
            isMulti={isMulti}
            className="sm:text-sm"
            classNamePrefix="react-select-override"
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: '',
                borderRadius: '',
              }),
              placeholder: (provided) => ({
                ...provided,
                marginLeft: '',
                color: '',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '',
              }),
            }}
            isClearable={isClearable}
            selected={
              field.value instanceof Array ? field.value || [] : field.value
            }
            onChange={(value) => {
              if (isMulti) {
                if (value === null) {
                  field.onChange([]);
                } else {
                  // @ts-expect-error
                  const v = value?.map(({ value }) => value);
                  field.onChange(v.length === 0 ? null : v);
                }
              } else {
                // @ts-expect-error
                const v = value?.value;
                field.onChange(v);
              }
            }}
          />
        )}
      />
    </BaseWrapper>
  );
};
