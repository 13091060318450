import type { DocumentNode } from 'graphql';
import { useState } from 'react';
import { useQuery } from 'urql';

import { Nullable } from '@/types';

export type PaginationProps<Variables> = {
  variables?: Variables;
};

export type Edges<Node = unknown> = {
  node: Node;
};

export type Paginatable<Node> = {
  edges: (Edges<Node> | null)[] | null;
  pageInfo: {
    endCursor: string | null;
    startCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

type PaginateStateType = {
  after: Nullable<string>;
  before: Nullable<string>;
  first: Nullable<number>;
  last: Nullable<number>;
};

const DEFAULT_LIMIT = 10;

export const usePagination = <Data, Variables extends {}, Item>(
  query: DocumentNode,
  objectMapper: (d: Data | undefined) => Paginatable<Item> | undefined,
  { variables }: PaginationProps<Variables>,
) => {
  const [pagination, setPagination] = useState<PaginateStateType>({
    after: null,
    before: null,
    first: DEFAULT_LIMIT,
    last: null,
  });

  const [{ data, fetching }] = useQuery<Data, Variables>({
    query,
    // onError: handleError(onError),
    requestPolicy: 'network-only',
    // @ts-expect-error
    variables: {
      ...variables,
      ...pagination,
    },
  });

  const object = objectMapper(data);

  return {
    items: object?.edges?.map((edge) => edge?.node) || [],
    loading: fetching && pagination.after === null,
    firstPage: object?.pageInfo.hasPreviousPage
      ? () =>
          setPagination({
            after: null,
            before: null,
            first: DEFAULT_LIMIT,
            last: null,
          })
      : null,
    nextPage: object?.pageInfo.hasNextPage
      ? () => {
          setPagination({
            after: object?.pageInfo?.endCursor,
            before: null,
            first: DEFAULT_LIMIT,
            last: null,
          });
        }
      : null,
    previousPage: object?.pageInfo.hasPreviousPage
      ? () => {
          setPagination({
            after: null,
            before: object?.pageInfo?.startCursor,
            first: null,
            last: DEFAULT_LIMIT,
          });
        }
      : null,
  };
};
