import React, { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { BaseInputFieldProps } from './BaseInputField';
import { BaseWrapper } from './BaseWrapper';

type Props = BaseInputFieldProps & ComponentProps<'textarea'>;

export const TextareaField = ({
  title,
  name,
  required,
  className,
  id,
  ...rest
}: Props) => {
  const { register } = useFormContext();
  return (
    <BaseWrapper
      title={title}
      name={name}
      className={className}
      id={id}
      required={required}
    >
      <textarea
        className="mt-1 focus:outline-none focus:ring block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder={title}
        autoComplete="off"
        id={id}
        {...rest}
        {...register(name, { required, ...rest })}
      />
    </BaseWrapper>
  );
};
