import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';

import { PredefinedNames, PredefinedTimeFrame } from './TimeFrame';

export const predefinedTimeFrames: Record<
  PredefinedNames,
  PredefinedTimeFrame
> = {
  today: new PredefinedTimeFrame('today', true, () => {
    const date = new Date();
    return {
      from: startOfDay(date),
      to: endOfDay(date),
    };
  }),
  yesterday: new PredefinedTimeFrame('yesterday', false, () => {
    const date = new Date();
    return {
      from: startOfDay(sub(date, { days: 1 })),
      to: endOfDay(sub(date, { days: 1 })),
    };
  }),
  'past-7-days': new PredefinedTimeFrame('past-7-days', true, () => {
    const date = new Date();
    return {
      from: startOfDay(sub(date, { days: 7 })),
      to: endOfDay(date),
    };
  }),
  'past-30-days': new PredefinedTimeFrame('past-30-days', true, () => {
    const date = new Date();
    return {
      from: startOfDay(sub(date, { days: 30 })),
      to: endOfDay(date),
    };
  }),
  'this-week': new PredefinedTimeFrame('this-week', true, () => {
    const date = new Date();
    return {
      from: startOfWeek(date),
      to: endOfDay(date),
    };
  }),
  'last-week': new PredefinedTimeFrame('last-week', false, () => {
    const date = sub(new Date(), { weeks: 1 });
    return {
      from: startOfWeek(date),
      to: endOfWeek(date),
    };
  }),
  'this-month': new PredefinedTimeFrame('this-month', true, () => {
    const date = new Date();
    return {
      from: startOfMonth(date),
      to: endOfDay(date),
    };
  }),
  'last-month': new PredefinedTimeFrame('last-month', false, () => {
    const date = sub(new Date(), { months: 1 });
    return {
      from: startOfMonth(date),
      to: endOfMonth(date),
    };
  }),
  'all-time': new PredefinedTimeFrame('all-time', false, () => {
    const date = new Date();
    return {
      from: new Date('2019-12-01'),
      to: endOfDay(date),
      nullableFrom: null,
      nullableTo: null,
    };
  }),
};
