import React from 'react';
import { MaskedInputProps } from 'react-text-mask';

import { BaseInputFieldProps } from './BaseInputField';
import { MaskedInputField } from './MaskedInputField';

export const IPAddressField = (props: BaseInputFieldProps) => {
  const allProps: MaskedInputProps & BaseInputFieldProps = {
    ...props,
    guide: false,
    mask: (value) => {
      const mask = Array(value.length).fill(/[\d.]/);
      const chunks = value.split('.');

      for (let i = 0; i < 4; ++i) {
        const chunk = chunks[i] || '';

        if (255 % +chunk === 255) {
          mask[value.length - 1] = '.';
          mask[value.length] = /[\d.]/;
        }
      }

      return mask;
    },
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) return false;

      const parts = value.split('.');

      if (
        parts.length > 4 ||
        parts.some(
          (part) => part === '00' || parseInt(part) < 0 || parseInt(part) > 255,
        )
      ) {
        return false;
      }

      return value;
    },
  };

  return <MaskedInputField {...allProps} />;
};
