import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from '@reach/router';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';

const TIMEOUT_MS = 3600 * 1000;

const STORAGE_KEY = 'userActivity';

const useStaleSignOut = () => {
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();
  const [lastActivityAt, setLastActivityAt] = useState<string | null>();

  useEffect(() => {
    setLastActivityAt(Date.now().toString());
  }, [location.pathname, setLastActivityAt]);

  useEffect(() => {
    const storageListener = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY) {
        setLastActivityAt(event.newValue);
      }
    };

    window.addEventListener('storage', storageListener, true);
    return () => window.removeEventListener('storage', storageListener);
  }, [setLastActivityAt]);

  useEffect(() => {
    if (lastActivityAt) {
      localStorage.setItem(STORAGE_KEY, lastActivityAt);
    }
    const timeoutId = setTimeout(() => {
      oktaAuth.signOut();
    }, TIMEOUT_MS);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [lastActivityAt, oktaAuth]);
};

const SecureRoute: FC = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const pendingLogin = useRef(false);

  useStaleSignOut();

  useEffect(() => {
    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (
      !authState.isAuthenticated &&
      !authState.isPending &&
      !pendingLogin.current
    ) {
      pendingLogin.current = true;
      oktaAuth.setOriginalUri();

      oktaAuth.signInWithRedirect();
    }
  }, [authState.isPending, authState.isAuthenticated, oktaAuth]);

  if (!authState.isAuthenticated) {
    return null;
  }

  return children as ReactElement;
};

export const Secure: FC = ({ children }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  return <SecureRoute>{children}</SecureRoute>;
};
