import classNames from 'classnames';
import React, { FC } from 'react';

export const Breadcrumbs: FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <nav
    className={classNames(
      'col-span-full whitespace-nowrap overflow-x-auto',
      className,
    )}
  >
    <ul className="list-reset flex text-gray-600 dark:text-gray-400 pt-3">
      {children}
    </ul>
  </nav>
);

export const Breadcrumb: FC<{ last?: boolean }> = ({ children, last }) => (
  <li className="flex items-center">
    {children}
    {!last && (
      <svg
        className="w-3 h-3 mx-2 mt-0.5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
          d="M9 5l7 7-7 7"
        />
      </svg>
    )}
  </li>
);
