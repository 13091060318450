import React from 'react';

import {
  AnalyticsDataSourceForm,
  ElasticIndexPrefixForm,
  ThemeForm,
} from '@/forms';
import { CogIcon } from './icons';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  InlineIconButton,
} from '.';

type Props = {
  buttonClassName?: string;
};

export const GlobalOptions = ({ buttonClassName }: Props) => (
  <ControlledModal
    content={
      <Card
        size="md"
        title="Global options"
        options={
          <CardOptions>
            <CardCloseButton />
          </CardOptions>
        }
      >
        <CardBody className="grid gap-6 p-3">
          <div>
            <ThemeForm />
          </div>
          <div>
            <ElasticIndexPrefixForm />
          </div>
          <div>
            <AnalyticsDataSourceForm />
          </div>
        </CardBody>
      </Card>
    }
  >
    <InlineIconButton className={buttonClassName}>
      <CogIcon />
    </InlineIconButton>
  </ControlledModal>
);
