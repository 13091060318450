import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { BaseInputFieldProps } from './BaseInputField';
import { BaseWrapper } from './BaseWrapper';

export const MaskedInputField = ({
  title,
  name,
  showTimeInput,
  ...props
}: BaseInputFieldProps & { showTimeInput?: boolean } & MaskedInputProps) => {
  const { control } = useFormContext();
  return (
    <BaseWrapper title={title} name={name} {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MaskedInput
            {...props}
            onChange={(e) => {
              field.onChange(e);
            }}
            placeholder={title}
            value={field.value}
            type="text"
            className="mt-1 focus:ring block w-full sm:text-sm border-gray-300 rounded-md"
          />
        )}
      />
    </BaseWrapper>
  );
};
