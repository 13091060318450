export class TranslatedError extends Error {}

export function assert<T>(
  condition: T,
  error: string | TranslatedError,
): asserts condition {
  if (!condition) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error);
  }
}
