import gql from 'graphql-tag';
import React, { Dispatch, FC, Reducer, useReducer } from 'react';

import { playerNotes_note } from '@/blocks/player-notes-block/usePlayerNotes';
import { NewNode_Note } from './__generated__/NewNode';
import { ContextBuilder } from './ContextBuilder';

type NewNodeEntry<T> = T & { __isNew: true };

type State = {
  Note: NewNodeEntry<NewNode_Note>[];
};

type AddAction<NodeType extends keyof State> = {
  type: 'ADD_NODE';
  nodeType: NodeType;
  node: Omit<State[NodeType][number], '__isNew'>;
};

type Action = AddAction<keyof State>;

export const newNodeFragment = gql`
  fragment NewNode on Node {
    ... on Note {
      id
      ...PlayerNotes_note
    }
  }
  ${playerNotes_note}
`;

const NewNodesContext = new ContextBuilder<State>('NewNodesContext');
const DispatchNewNodesContext = new ContextBuilder<Dispatch<Action>>(
  'DispatchNewNodesContext',
);

export const useNewNodes = NewNodesContext.use;
export const useNewNodesOf = (t: keyof State) => useNewNodes()[t];
export const useDispatchNewNodes = DispatchNewNodesContext.use;

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'ADD_NODE':
      return {
        ...state,
        [action.nodeType]: [
          { ...action.node, __isNew: true },
          ...state[action.nodeType],
        ],
      };
    default:
      return state;
  }
};

export const NewNodesProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    Note: [],
  });

  return (
    <DispatchNewNodesContext.Context.Provider value={dispatch}>
      <NewNodesContext.Context.Provider value={state}>
        {children}
      </NewNodesContext.Context.Provider>
    </DispatchNewNodesContext.Context.Provider>
  );
};
