import { OktaAuth } from '@okta/okta-auth-js';

export const makeAuthClient = () => {
  const authClient = new OktaAuth({
    issuer: process.env.GATSBY_OKTA_ISSUER_URL,
    clientId: process.env.GATSBY_OKTA_CLIENT_ID,
    redirectUri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/implicit/callback`,
    scopes: ['openid', 'email', 'profile'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
      secure: true,
      storage: 'localStorage',
    },
  });
  return authClient;
};
