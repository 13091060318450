import classNames from 'classnames';
import React, { ComponentProps, JSXElementConstructor } from 'react';

type AnyElement = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

export type ButtonProps<T extends AnyElement> = {
  variant?: 'primary' | 'secondary' | 'outlined';
  as?: T;
} & ComponentProps<T>;

const disableFocusStyles =
  'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus:outline-none';

const primaryStyles = {
  default:
    'shadow py-2 px-4 rounded bg-purple-500 hover:bg-purple-400 text-white font-bold',
  disabled:
    'shadow py-2 px-4 rounded bg-purple-300 hover:bg-purple-300 text-white font-bold',
};

const secondaryStyles = {
  default: 'shadow py-2 px-4 rounded bg-gray-200 hover:bg-gray-300 font-bold',
  disabled:
    'shadow py-2 px-4 rounded text-gray-400 bg-gray-100 hover:bg-gray-100 font-bold',
};

const outlinedStyles = {
  default:
    'shadow py-2 px-4 rounded text-black bg-gray-500 bg-opacity-0 hover:bg-opacity-20 border border-gray-500',
  disabled:
    'shadow py-2 px-4 rounded text-gray-300 bg-opacity-0 border border-gray-300',
};

export const Button = <T extends AnyElement = 'button'>({
  className,
  as = 'button',
  variant,
  ...rest
}: ButtonProps<T>) => {
  return React.createElement(as, {
    className: classNames(
      disableFocusStyles,
      {
        [primaryStyles.default]: variant === 'primary' && !rest.disabled,
        [primaryStyles.disabled]: variant === 'primary' && rest.disabled,
        [secondaryStyles.default]: variant === 'secondary' && !rest.disabled,
        [secondaryStyles.disabled]: variant === 'secondary' && rest.disabled,
        [outlinedStyles.default]: variant === 'outlined' && !rest.disabled,
        [outlinedStyles.disabled]: variant === 'outlined' && rest.disabled,
        'cursor-not-allowed': rest.disabled,
      },
      className,
    ),
    ...rest,
  });
};

export const SubmitButton = ({
  as = 'input',
  variant = 'primary',
  ...rest
}: ButtonProps<'input' | 'button'>) => (
  <div className="col-span-full ">
    <Button
      as={as}
      variant={variant}
      type="submit"
      style={{ minWidth: 200 }}
      {...rest}
    />
  </div>
);
