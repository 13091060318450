import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { BaseInputFieldProps } from './BaseInputField';
import { ErrorMessage } from './ErrorMessage';

export const BaseWrapper: React.FC<BaseInputFieldProps> = ({
  title,
  required,
  children,
  className,
  name,
  id,
}) => {
  const { formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <div className={classNames('w-full', className)}>
      <label
        className={classNames(
          'block tracking-wide text-gray-700 dark:text-white text-sm font-semibold truncate',
          {
            'text-red-600': !!error,
          },
        )}
        htmlFor={id ?? name}
      >
        {title}
        {required && '*'}
      </label>
      {children}
      {error && <ErrorMessage message={error} variant="inline" />}
    </div>
  );
};
