import { useState } from 'react';
import { QueryParamConfigMap, useQueryParams } from 'use-query-params';

import { useTime } from '@/contexts';

type InputOptions = {
  fromField?: string;
  toField?: string;
  fromAndToOptional?: boolean;
};

const getOptions = (inputOptions: InputOptions) => ({
  fromField: inputOptions.fromField ?? 'from',
  toField: inputOptions.toField ?? 'to',
  fromAndToOptional: inputOptions.fromAndToOptional ?? false,
});

export const useQueryParamsWithTime = <T extends QueryParamConfigMap>(
  params: T,
  inputOptions: InputOptions = {},
) => {
  const options = getOptions(inputOptions);

  const { time } = useTime();
  const [query, setQuery] = useQueryParams<T>(params);

  const from = (options.fromAndToOptional && time.nullableFrom) || time.from;
  const to = (options.fromAndToOptional && time.nullableTo) || time.to;

  const [useLocalTime, setUseLocalTime] = useState(
    !!(query[options.fromField] || query[options.toField]),
  );

  const filter: typeof query = {
    ...query,
    ...(useLocalTime
      ? {}
      : { [options.fromField]: from, [options.toField]: to }),
  };

  const updater: typeof setQuery = (value, updateType) => {
    let resultValue = value;
    if (typeof value === 'function') {
      resultValue = value(query);
    }
    if (options.fromField in resultValue || options.toField in resultValue) {
      setUseLocalTime(true);
    }
    setQuery(resultValue, updateType);
  };

  return [filter, updater] as const;
};
